import * as Types from '../../../../api/graphql';

import gql from 'graphql-tag';
import { FamilyAttendanceFieldsFragmentDoc } from '../../../shared/family/attendance/generated/familyAttendanceFields.generated';
type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type FamilyAttendanceQueryVariables = Types.Exact<{
  family: Types.Scalars['ID'];
  sorts?: Types.InputMaybe<Array<Types.Sort> | Types.Sort>;
  filters?: Types.InputMaybe<Array<Types.Filter> | Types.Filter>;
}>;

export type FamilyAttendanceQuery = {
  family?:
    | {
        attendancesQuery?:
          | {
              count?: number | null | undefined;
              items?:
                | Array<{
                    id: string;
                    date?: string | null | undefined;
                    sessionDateInTz?: string | null | undefined;
                    checkedIn?: Types.CheckedIn | null | undefined;
                    formattedCheckedInTime?: string | null | undefined;
                    checkedOut?: boolean | null | undefined;
                    formattedCheckedOutTime?: string | null | undefined;
                    formattedSessionStartTime: string;
                    formattedSessionEndTime: string;
                    paymentStatus?: Types.FamilyPaymentStatus | null | undefined;
                    student?: { id: string; nameWithNickname: string } | null | undefined;
                    checkedInBy?:
                      | { name?: string | null | undefined }
                      | { name?: string | null | undefined }
                      | { name?: string | null | undefined }
                      | { name?: string | null | undefined }
                      | { name?: string | null | undefined }
                      | { name: string }
                      | { name?: string | null | undefined }
                      | null
                      | undefined;
                    checkedOutBy?:
                      | { name?: string | null | undefined }
                      | { name?: string | null | undefined }
                      | { name?: string | null | undefined }
                      | { name?: string | null | undefined }
                      | { name?: string | null | undefined }
                      | { name: string }
                      | { name?: string | null | undefined }
                      | null
                      | undefined;
                    enrollment: { id: string; course: { id: string; name?: string | null | undefined } };
                    usageInstance?:
                      | {
                          listPrice: number;
                          discountAmount: number;
                          purchaseAmount: number;
                          refundsTotal: number;
                          balance: number;
                          priceConfig?: { formattedNonBillableTime: string; listRate?: number | null | undefined; timeInCourse: string; timeBilled: string; billingDate?: string | null | undefined } | null | undefined;
                        }
                      | null
                      | undefined;
                  }>
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export const FamilyAttendanceDocument = gql`
  query FamilyAttendance($family: ID!, $sorts: [Sort!], $filters: [Filter!]) {
    family(family: $family) {
      attendancesQuery(sorts: $sorts, filters: $filters) {
        ...familyAttendanceFields
      }
    }
  }
  ${FamilyAttendanceFieldsFragmentDoc}
`;

import { MakeOptional } from 'app2/api';
import { executeQuery, ExecQueryOptions, useQuery, UseQueryArgs } from 'app2/views/shared/urql';

export type FamilyAttendanceArgs = MakeOptional<UseQueryArgs<FamilyAttendanceQueryVariables, FamilyAttendanceQuery>, 'query'>;

export function useFamilyAttendanceQuery(options: FamilyAttendanceArgs = {}) {
  return useQuery<FamilyAttendanceQueryVariables, FamilyAttendanceQuery>({ query: FamilyAttendanceDocument, ...options });
}

export type FamilyAttendanceOptions = ExecQueryOptions<FamilyAttendanceQueryVariables>;

export function familyAttendance(options: FamilyAttendanceOptions) {
  return executeQuery<FamilyAttendanceQuery, FamilyAttendanceQueryVariables>(FamilyAttendanceDocument, options);
}

export type FamilyAttendanceSelections = FamilyAttendanceQuery['family'];
