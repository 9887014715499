// ensures that attributes are in the format that the server will accept
// numbers need to numbers not strings
// undefineds need to be nulls or they won't serialize

import { Course, CourseInput, UsageUnit } from 'app2/api';
import { hasNewId } from 'app2/components';

function nullOrNumber(val:any) {
  if (val === '') {
    return null;
  }

  return Number.isFinite(val) ? Number(val) : null;
}

function nullOrObj(val:any) {
  return val === undefined ? null : val;
}

function nullOrDateTime(val:any) {
  return val === undefined || val === '' ? null : val;
}

function array(val:any) {
  return val === undefined || val === null ? [] : val;
}

function string(val:any) {
  return val || '';
}

export function createCoursePayload(course:Partial<Course>):CourseInput {
  const newFramework = hasNewId(course.courseFramework);
  const newTeacher = hasNewId(course.teacher);

  return {
    id: course.id,
    courseCardImage: course.courseCardImage,
    courseDays: array(course.courseDays),
    courseFrameworkId: !newFramework ? nullOrObj(course.courseFramework?.id) : undefined,
    courseFrameworkAttributes: newFramework
      ? {
        companyId: (course.courseFramework as any).companyId,
        courseCardImage: course.courseFramework.courseCardImage,
        courseImage: course.courseFramework.courseImage,
        courseTagIds: array(course.courseFramework.courseTags?.filter(tag => !!tag).map(tag => tag.id)),
        description: string(course.courseFramework.description),
        name: course.courseFramework.name,
        supplies: array(course.courseFramework.supplies)
      }
      : undefined,
    courseImage: course.courseImage,
    courseTagIds: array(course.courseTags?.filter(tag => !!tag).map(tag => tag.id)),
    dailyCapacity: course.dailyCapacity,
    description: course.description,
    endDate: course.endDate,
    enrollmentCloses: nullOrDateTime(course.enrollmentCloses),
    enrollmentOpens: nullOrDateTime(course.enrollmentOpens),
    feeRate: course.feeRate,
    feeType: course.feeType,
    grades: array(course.grades),
    ageMin: nullOrNumber(course.ageMin),
    ageMax: nullOrNumber(course.ageMax),
    autoCheckoutStudents: course.autoCheckoutStudents,
    attendanceEnabled: course.attendanceEnabled,
    maxCapacity: nullOrNumber(course.maxCapacity),
    minCapacity: nullOrNumber(course.minCapacity),
    teamMaxCapacity: nullOrNumber(course.teamMaxCapacity),
    name: course.name,
    kind: nullOrObj(course.kind),
    noEnrichmentDays: array(course.noEnrichmentDays),
    // needed because the UI loads choices as {id: string}[] but the server expects string[] and while
    // the ui will auto convert this during editing, it won't if there's no edits but we resent these for any edit to the course
    options: course.options ? course.options.map(option => ({...option, choices: (option.choices || []).map(course => typeof course == 'string' ? course : course.id)})) : undefined,
    rates: {
      season: course.rates?.season || {},
      seasons: course.rates?.seasons || [],
      dropIn: course.rates?.dropIn || {},
      recurring: course.rates?.recurring || [],
      usage: (course.rates?.usage || []).filter(r => r.unit === UsageUnit.Day || r.unit === UsageUnit.Hour || r.roundingIncrement != null)
    },
    room: course.room,
    searchable: course.searchable,
    startDate: course.startDate,
    supplies: course.supplies,
    teacherId: !newTeacher ? nullOrObj(course.teacher?.id) : undefined,
    teacherAttributes: newTeacher
      ? {
        companyId: (course.teacher as any).companyId,
        email: course.teacher.email,
        name: course.teacher.name,
        phone: course.teacher.phone
    } 
    : undefined,
    vendorId: nullOrObj(course.vendor?.id),
    siteId: nullOrObj(course.site?.id),
    // game args
    homeTeamId: course.homeTeam?.id,
    awayTeamId: course.awayTeam?.id,
    homeScore: nullOrNumber(course.homeScore),
    awayScore: nullOrNumber(course.awayScore),
  }
}
