export interface GqlTypes {
  [type: string]: GqlType;
}

export interface GqlType {
  [prop: string]: GqlProp;
}

export interface GqlProp {
  type: string;
  array?: boolean;
  resolvedType?: GqlType;
  format?: string;
}

export const gqlTypes: GqlTypes = {
  AddBreakdown: {
    charges: { type: 'ChargeBreakdown', array: true },
    refund: { type: 'Float', array: false }
  },
  AddEnrollmentPriceBreakdown: {
    first: { type: 'AddBreakdown', array: false },
    future: { type: 'AddBreakdown', array: false },
    priceConfig: { type: 'PriceConfig', array: false },
    standard: { type: 'ChargeBreakdown', array: false }
  },
  Adjustment: {
    createdAt: { type: 'ISO8601DateTime', array: false },
    id: { type: 'ID', array: false },
    total: { type: 'Float', array: false }
  },
  Allergy: {
    allergicTo: { type: 'String', array: false },
    id: { type: 'ID', array: false },
    notes: { type: 'String', array: false }
  },
  AthleticsConfig: {
    students: { type: 'AthleticsConfigStudent', array: true },
    teamName: { type: 'String', array: false }
  },
  AthleticsConfigStudent: {
    email: { type: 'String', array: false },
    name: { type: 'String', array: false }
  },
  Attendance: {
    checkedIn: { type: 'CheckedIn', array: false },
    checkedInAt: { type: 'ISO8601DateTime', array: false },
    checkedInBy: { type: 'Person', array: false },
    checkedOut: { type: 'Boolean', array: false },
    checkedOutAt: { type: 'ISO8601DateTime', array: false },
    checkedOutBy: { type: 'Person', array: false },
    checkoutDest: { type: 'String', array: false },
    date: { type: 'ISO8601Date', array: false },
    enrollment: { type: 'Enrollment', array: false },
    formattedCheckedIn: { type: 'String', array: false },
    formattedCheckedInTime: { type: 'String', array: false },
    formattedCheckedOut: { type: 'String', array: false },
    formattedCheckedOutTime: { type: 'String', array: false },
    formattedSessionEndTime: { type: 'String', array: false },
    formattedSessionStartTime: { type: 'String', array: false },
    id: { type: 'ID', array: false },
    paymentStatus: { type: 'FamilyPaymentStatus', array: false },
    paymentStatusLabel: { type: 'String', array: false },
    sessionDate: { type: 'ISO8601Date', array: false, format: 'local' },
    sessionDateInTz: { type: 'ISO8601Date', array: false, format: 'utc' },
    student: { type: 'Student', array: false },
    usageInstance: { type: 'Enrollment', array: false }
  },
  BetaFlags: {
    organizerRedesign: { type: 'OrganizerRedesign', array: false },
    parentRedesign: { type: 'ParentRedesign', array: false }
  },
  BulkRefundBreakdown: {
    familiesReceive: { type: 'Float', array: false },
    individualBreakdowns: { type: 'RefundBreakdown', array: true },
    nonrefundableSiteFee: { type: 'Float', array: false },
    otherFeeReversals: { type: 'Float', array: false },
    providerWithdrawAmount: { type: 'Float', array: false },
    siteWithdrawAmount: { type: 'Float', array: false }
  },
  Cart: {
    alipayToken: { type: 'StripePaymentToken', array: false },
    cartExpiration: { type: 'ISO8601DateTime', array: false },
    contentsChecksum: { type: 'String', array: false },
    courseDiscountTotal: { type: 'Float', array: false },
    discountTotal: { type: 'Float', array: false },
    donation: { type: 'Donation', array: false },
    dueLater: { type: 'Float', array: false },
    enrollments: { type: 'Enrollment', array: true },
    family: { type: 'Family', array: false },
    id: { type: 'ID', array: false },
    paymentMethodRequired: { type: 'Boolean', array: false },
    paymentService: { type: 'PaymentService', array: false },
    priceTierDiscountTotal: { type: 'Float', array: false },
    priceTierUse: { type: 'PriceTierUse', array: false },
    seasons: { type: 'Season', array: true },
    sites: { type: 'Site', array: true },
    stripeSetupIntentToken: { type: 'String', array: false },
    students: { type: 'Student', array: true },
    subtotal: { type: 'Float', array: false },
    total: { type: 'Float', array: false }
  },
  CartItem: {
    added: { type: 'ISO8601Date', array: false },
    additionalDiscount: { type: 'Discount', array: false },
    affirmEnabled: { type: 'Boolean', array: false },
    ageAtStart: { type: 'Int', array: false },
    amount: { type: 'Float', array: false },
    athleticsConfig: { type: 'AthleticsConfig', array: false },
    balance: { type: 'Float', array: false },
    billPeriod: { type: 'String', array: false },
    billedAt: { type: 'ISO8601DateTime', array: false },
    billedDate: { type: 'ISO8601Date', array: false },
    billingDescription: { type: 'String', array: false },
    billingStartDate: { type: 'ISO8601Date', array: false },
    cfAnswers: { type: 'JSON', array: false },
    cfFormattedAnswers: { type: 'JSON', array: false },
    cfFormattedSeasonAnswers: { type: 'JSON', array: false },
    cfQuestions: { type: 'CfQuestion', array: true },
    cfSeasonAnswers: { type: 'JSON', array: false },
    childEnrollments: { type: 'Enrollment', array: true },
    childOptions: { type: 'Enrollment', array: true },
    company: { type: 'Company', array: false },
    contacts: { type: 'Contact', array: true },
    course: { type: 'Course', array: false },
    courseOption: { type: 'CourseOption', array: false },
    createdAt: { type: 'ISO8601DateTime', array: false },
    discount: { type: 'Discount', array: false },
    discountAmount: { type: 'Float', array: false },
    discountCodes: { type: 'String', array: true },
    discountId: { type: 'ID', array: false },
    discountUses: { type: 'DiscountUse', array: true },
    discountedPrice: { type: 'Float', array: false },
    dismissal: { type: 'String', array: false },
    endDate: { type: 'ISO8601Date', array: false },
    enrollmentOptions: { type: 'EnrollmentOption', array: true },
    enrollmentPriceTierUse: { type: 'EnrollmentPriceTierUse', array: false },
    family: { type: 'Family', array: false },
    firstPayment: { type: 'FirstPayment', array: false },
    formattedDays: { type: 'String', array: false },
    formattedDaysAndTimes: { type: 'String', array: false },
    formattedOtherEnrollments: { type: 'String', array: false },
    formattedTimes: { type: 'String', array: false },
    gradeAtStart: { type: 'String', array: false },
    groups: { type: 'String', array: true },
    id: { type: 'ID', array: false },
    inAgeRange: { type: 'Boolean', array: false },
    inGradeRange: { type: 'Boolean', array: false },
    invitationExpired: { type: 'Boolean', array: false },
    isChildEnrollment: { type: 'Boolean', array: false },
    isInvitation: { type: 'Boolean', array: false },
    isOption: { type: 'Boolean', array: false },
    isOptionParent: { type: 'Boolean', array: false },
    isParentEnrollment: { type: 'Boolean', array: false },
    isScholarshipHold: { type: 'Boolean', array: false },
    lateStart: { type: 'Boolean', array: false },
    listPrice: { type: 'Float', array: false },
    optionParent: { type: 'Enrollment', array: false },
    otherEnrollments: { type: 'Enrollment', array: true },
    overlaps: { type: 'Boolean', array: false },
    parent: { type: 'GuardedUser', array: false },
    parentEnrollment: { type: 'Enrollment', array: false },
    parents: { type: 'GuardedUser', array: true },
    paymentFailureReason: { type: 'String', array: false },
    paymentService: { type: 'PaymentService', array: false },
    paymentStatus: { type: 'PaymentStatus', array: false },
    pendingChange: { type: 'EnrollmentChange', array: false },
    pendingInstances: { type: 'Enrollment', array: true },
    perSessionRefunds: { type: 'Boolean', array: false },
    pickup: { type: 'String', array: false },
    pickupDropoffRequired: { type: 'Boolean', array: false },
    priceConfig: { type: 'PriceConfig', array: false },
    priceConfigs: { type: 'PriceConfig', array: true },
    priceTierUse: { type: 'PriceTierUse', array: false },
    purchaseAmount: { type: 'Float', array: false },
    purchaseDescription: { type: 'String', array: false },
    purchased: { type: 'Boolean', array: false },
    refunds: { type: 'Refund', array: true },
    refundsTotal: { type: 'Float', array: false },
    rosterAmount: { type: 'String', array: false },
    rosterPeriod: { type: 'String', array: false },
    rosterPeriods: { type: 'String', array: false },
    season: { type: 'Season', array: false },
    startDate: { type: 'ISO8601Date', array: false },
    status: { type: 'EnrollmentStatusEnum', array: false },
    student: { type: 'Student', array: false },
    studentSelectionRequired: { type: 'Boolean', array: false },
    templateId: { type: 'String', array: false },
    trackingParameters: { type: 'String', array: false },
    waitlistKind: { type: 'WaitlistKind', array: false },
    weekday: { type: 'String', array: false },
    withdrawable: { type: 'Boolean', array: false },
    checkedIn: { type: 'CheckedIn', array: false },
    checkedInAt: { type: 'ISO8601DateTime', array: false },
    checkedInBy: { type: 'String', array: false },
    checkedOut: { type: 'Boolean', array: false },
    checkedOutAt: { type: 'ISO8601DateTime', array: false },
    checkedOutBy: { type: 'String', array: false },
    checkoutDest: { type: 'String', array: false },
    endsAt: { type: 'ISO8601DateTime', array: false },
    formattedEndsAt: { type: 'String', array: false },
    formattedStartsAt: { type: 'String', array: false },
    startsAt: { type: 'ISO8601DateTime', array: false },
    updatedAt: { type: 'ISO8601DateTime', array: false }
  },
  CfAnswer: {
    answers: { type: 'JSON', array: false },
    id: { type: 'ID', array: false },
    ownerId: { type: 'ID', array: false },
    ownerType: { type: 'CfOwnerKind', array: false }
  },
  CfQuestion: {
    choices: { type: 'String', array: true },
    description: { type: 'String', array: false },
    files: { type: 'FileUpload', array: true },
    filterCourseKinds: { type: 'CourseKind', array: true },
    filterCourses: { type: 'String', array: true },
    id: { type: 'ID', array: false },
    kind: { type: 'CfKind', array: false },
    owner: { type: 'WithName', array: false },
    ownerId: { type: 'ID', array: false },
    ownerType: { type: 'CfOwnerKind', array: false },
    required: { type: 'Boolean', array: false },
    stage: { type: 'CfStage', array: false },
    title: { type: 'String', array: false }
  },
  ChargeBreakdown: {
    amount: { type: 'Float', array: false },
    date: { type: 'ISO8601Date', array: false },
    discountAmount: { type: 'Float', array: false },
    discounts: { type: 'DiscountUse', array: true },
    homeroomCharges: { type: 'Float', array: false },
    listPrice: { type: 'Float', array: false },
    period: { type: 'String', array: false },
    prorated: { type: 'Boolean', array: false },
    siteCharges: { type: 'Float', array: false },
    siteCreditCardFee: { type: 'Float', array: false },
    siteReceives: { type: 'Float', array: false },
    vendorCharges: { type: 'Float', array: false },
    vendorCreditCardFee: { type: 'Float', array: false },
    vendorReceives: { type: 'Float', array: false }
  },
  Classroom: {
    displayName: { type: 'String', array: false },
    grade: { type: 'String', array: false },
    id: { type: 'ID', array: false },
    name: { type: 'String', array: false },
    site: { type: 'Site', array: false },
    siteId: { type: 'String', array: false }
  },
  Company: {
    about: { type: 'String', array: false },
    address: { type: 'String', array: false },
    cancellationPolicy: { type: 'VendorGuideline', array: false },
    city: { type: 'String', array: false },
    courseCancellationPolicy: { type: 'String', array: false },
    courseFrameworks: { type: 'CourseFramework', array: true },
    courseFrameworksQuery: { type: 'CourseFrameworksQueryResults', array: false },
    courseKindGroups: { type: 'CourseKindGroup', array: true },
    courses: { type: 'Course', array: true },
    email: { type: 'String', array: false },
    hasCourseFrameworks: { type: 'Boolean', array: false },
    hasCourses: { type: 'Boolean', array: false },
    hasMutations: { type: 'Boolean', array: false },
    hasParticipants: { type: 'Boolean', array: false },
    hasRequests: { type: 'Boolean', array: false },
    hasTableViews: { type: 'Boolean', array: false },
    hasTeachers: { type: 'Boolean', array: false },
    hasTeamAdmins: { type: 'Boolean', array: false },
    hasTeamMembers: { type: 'Boolean', array: false },
    headline: { type: 'String', array: false },
    history: { type: 'HistoryVersion', array: true },
    id: { type: 'ID', array: false },
    mutationChanges: { type: 'MutationChange', array: true },
    mutationChangesQuery: { type: 'MutationChangesQueryResults', array: false },
    mutations: { type: 'Mutation', array: true },
    mutationsQuery: { type: 'MutationsQueryResults', array: false },
    name: { type: 'String', array: false },
    nonPartnerApproved: { type: 'Boolean', array: false },
    participants: { type: 'Participant', array: true },
    participantsQuery: { type: 'ParticipantsQueryResults', array: false },
    payouts: { type: 'Payout', array: true },
    pendingPayoutTotal: { type: 'Float', array: false },
    phone: { type: 'String', array: false },
    profileBackground: { type: 'String', array: false },
    profilePhoto: { type: 'String', array: false },
    quickStartGuide: { type: 'CompanyQuickStartGuide', array: false },
    sites: { type: 'Site', array: true },
    sitesQuery: { type: 'SitesQueryResults', array: false },
    size: { type: 'Int', array: false },
    slug: { type: 'String', array: false },
    state: { type: 'String', array: false },
    stripeAccount: { type: 'String', array: false },
    stripeAccountType: { type: 'String', array: false },
    stripeAccountVerified: { type: 'Boolean', array: false },
    stripeBalance: { type: 'Float', array: false },
    stripeCanIssueRefunds: { type: 'Boolean', array: false },
    stripeConnectUrl: { type: 'String', array: false },
    stripeDashboardUrl: { type: 'String', array: false },
    tableViews: { type: 'TableView', array: true },
    tableViewsQuery: { type: 'TableViewsQueryResults', array: false },
    tableViewsUpsert: { type: 'Boolean', array: false },
    tableViewsUpsertPersonal: { type: 'Boolean', array: false },
    teachers: { type: 'Teacher', array: true },
    teachersQuery: { type: 'TeachersQueryResults', array: false },
    teachingRadius: { type: 'Int', array: false },
    teamAdmins: { type: 'Member', array: true },
    teamAdminsQuery: { type: 'TeamAdminsQueryResults', array: false },
    teamChangeRole: { type: 'Boolean', array: false },
    teamInvites: { type: 'MemberInvitation', array: true },
    teamMembers: { type: 'Member', array: true },
    teamMembersQuery: { type: 'TeamMembersQueryResults', array: false },
    teamParticipantsUpsert: { type: 'Boolean', array: false },
    teamSetAsContact: { type: 'Boolean', array: false },
    timezone: { type: 'String', array: false },
    timezoneDisplay: { type: 'String', array: false },
    upcomingActiveOrRecentCourses: { type: 'Course', array: true },
    userCompanyRole: { type: 'MemberRole', array: false },
    userCourseRole: { type: 'MemberRole', array: false },
    userFamilyRole: { type: 'MemberRole', array: false },
    userMemberRole: { type: 'MemberRole', array: false },
    userSiteRole: { type: 'MemberRole', array: false },
    vendors: { type: 'Vendor', array: true },
    website: { type: 'String', array: false },
    zipcode: { type: 'String', array: false }
  },
  CompanyDirectoryResult: {
    course: { type: 'Course', array: false },
    distance: { type: 'Int', array: false },
    reviewAverage: { type: 'Float', array: false },
    reviewCount: { type: 'Int', array: false }
  },
  CompanyQuickStartGuide: {
    courseFrameworksComplete: { type: 'Boolean', array: false },
    coursesComplete: { type: 'Boolean', array: false },
    hidden: { type: 'Boolean', array: false },
    intructorsComplete: { type: 'Boolean', array: false },
    profileComplete: { type: 'Boolean', array: false },
    stripeComplete: { type: 'Boolean', array: false }
  },
  ComplianceDefinition: {
    complianceType: { type: 'String', array: false },
    downloads: { type: 'FileUpload', array: true },
    id: { type: 'ID', array: false },
    instructions: { type: 'String', array: false },
    name: { type: 'String', array: false },
    site: { type: 'Site', array: false }
  },
  ComplianceDefinitions: {
    teacher: { type: 'ComplianceDefinition', array: true },
    vendor: { type: 'ComplianceDefinition', array: true }
  },
  ComplianceReportRow: {
    files: { type: 'String', array: false },
    instructor: { type: 'String', array: false },
    provider: { type: 'String', array: false },
    requirement: { type: 'String', array: false },
    status: { type: 'String', array: false },
    type: { type: 'String', array: false }
  },
  ComplianceRequirement: {
    definition: { type: 'ComplianceDefinition', array: false },
    expiresAt: { type: 'ISO8601Date', array: false },
    id: { type: 'ID', array: false },
    status: { type: 'String', array: false },
    target: { type: 'ComplianceTarget', array: false },
    uploads: { type: 'FileUpload', array: true }
  },
  ComplianceTarget: {
    accepted: { type: 'Boolean', array: false },
    cancellationPolicy: { type: 'VendorGuideline', array: false },
    company: { type: 'Company', array: false },
    companyName: { type: 'String', array: false },
    complianceRequirements: { type: 'ComplianceRequirement', array: true },
    contacts: { type: 'Contact', array: true },
    courseCancellationPolicy: { type: 'String', array: false },
    courses: { type: 'Course', array: true },
    displayName: { type: 'String', array: false },
    email: { type: 'String', array: false },
    id: { type: 'ID', array: false },
    inCompliance: { type: 'Boolean', array: false },
    name: { type: 'String', array: false },
    nickName: { type: 'String', array: false },
    phone: { type: 'String', array: false },
    showProviderCompliance: { type: 'Boolean', array: false },
    showTeacherCompliance: { type: 'Boolean', array: false },
    site: { type: 'Site', array: false },
    siteCompany: { type: 'Boolean', array: false },
    teachers: { type: 'Teacher', array: true },
    token: { type: 'String', array: false },
    userIsMember: { type: 'Boolean', array: false },
    vendorInCompliance: { type: 'Boolean', array: false },
    vendorTeachers: { type: 'VendorTeacher', array: true },
    teacher: { type: 'Teacher', array: false }
  },
  Contact: {
    attendanceCodeCode: { type: 'String', array: false },
    email: { type: 'String', array: false },
    firstName: { type: 'String', array: false },
    id: { type: 'ID', array: false },
    kind: { type: 'ParticipantKind', array: false },
    lastName: { type: 'String', array: false },
    name: { type: 'String', array: false },
    normalizedRole: { type: 'MemberRole', array: false },
    owner: { type: 'WithName', array: false },
    phone: { type: 'String', array: false },
    position: { type: 'String', array: false },
    relation: { type: 'String', array: false },
    role: { type: 'String', array: false }
  },
  Course: {
    ageGroup: { type: 'String', array: false },
    ageMax: { type: 'Int', array: false },
    ageMin: { type: 'Int', array: false },
    allDaysFull: { type: 'Boolean', array: false },
    attendanceEnabled: { type: 'Boolean', array: false },
    autoCheckoutStudents: { type: 'Boolean', array: false },
    awayScore: { type: 'Int', array: false },
    awayTeam: { type: 'Course', array: false },
    behavior: { type: 'CourseBehavior', array: false },
    cancelledAt: { type: 'ISO8601DateTime', array: false },
    cartedCount: { type: 'Int', array: false },
    cfQuestions: { type: 'CfQuestion', array: true },
    childCourses: { type: 'Course', array: true },
    childOptionCourses: { type: 'Course', array: true },
    childVariantCourses: { type: 'Course', array: true },
    children: { type: 'Course', array: true },
    classesCount: { type: 'Int', array: false },
    closed: { type: 'Boolean', array: false },
    company: { type: 'Company', array: false },
    cost: { type: 'Float', array: false },
    courseCardImage: { type: 'String', array: false },
    courseDays: { type: 'CourseDay', array: true },
    courseDaysSortKey: { type: 'String', array: false },
    courseFramework: { type: 'CourseFramework', array: false },
    courseFrameworkId: { type: 'ID', array: false },
    courseImage: { type: 'String', array: false },
    courseTags: { type: 'CourseTag', array: true },
    dailyCapacity: { type: 'Boolean', array: false },
    days: { type: 'String', array: true },
    description: { type: 'String', array: false },
    disambiguatedName: { type: 'String', array: false },
    discardedAt: { type: 'ISO8601DateTime', array: false },
    effectiveSiteFeeRate: { type: 'Float', array: false },
    effectiveSiteFeeType: { type: 'SiteFeeTypeEnum', array: false },
    endDate: { type: 'ISO8601Date', array: false },
    enrolledCount: { type: 'Int', array: false },
    enrolledCountByDay: { type: 'JSON', array: false },
    enrollmentCloses: { type: 'ISO8601DateTime', array: false },
    enrollmentOpens: { type: 'ISO8601DateTime', array: false },
    enrollments: { type: 'Enrollment', array: true },
    enrollmentsQuery: { type: 'EnrollmentsQueryResults', array: false },
    events: { type: 'Event', array: true },
    eventsQuery: { type: 'EventsQueryResults', array: false },
    feeRate: { type: 'Float', array: false },
    feeType: { type: 'SiteFeeTypeEnum', array: false },
    forRosterWithInvitesCount: { type: 'Int', array: false },
    formattedCourseDays: { type: 'String', array: false },
    formattedDaysAndTimes: { type: 'String', array: false },
    formattedKind: { type: 'String', array: false },
    gradeGroup: { type: 'String', array: false },
    grades: { type: 'String', array: true },
    hasCheckoutOptions: { type: 'Boolean', array: false },
    hasEnrolledStudents: { type: 'Boolean', array: false },
    hasOptionParents: { type: 'Boolean', array: false },
    hasOptions: { type: 'Boolean', array: false },
    hasParentCourses: { type: 'Boolean', array: false },
    hasParentEnrollments: { type: 'Boolean', array: false },
    hasParentOptionCourses: { type: 'Boolean', array: false },
    homeScore: { type: 'Int', array: false },
    homeTeam: { type: 'Course', array: false },
    id: { type: 'ID', array: false },
    kind: { type: 'CourseKind', array: false },
    lastWithdrawDate: { type: 'ISO8601DateTime', array: false },
    listPrice: { type: 'Float', array: false },
    materialsFee: { type: 'Float', array: false },
    maxCapacity: { type: 'Int', array: false },
    messages: { type: 'Message', array: true },
    minCapacity: { type: 'Int', array: false },
    name: { type: 'String', array: false },
    nameForRoster: { type: 'String', array: false },
    noEnrichmentDays: { type: 'ISO8601Date', array: true },
    notes: { type: 'String', array: false },
    optionParents: { type: 'Enrollment', array: true },
    optionParentsQuery: { type: 'OptionParentsQueryResults', array: false },
    options: { type: 'CourseOption', array: true },
    parent: { type: 'Course', array: false },
    parentCourses: { type: 'Course', array: true },
    parentEnrollments: { type: 'Enrollment', array: true },
    parentEnrollmentsQuery: { type: 'ParentEnrollmentsQueryResults', array: false },
    parentOptionCourses: { type: 'Course', array: true },
    parentVariant: { type: 'Course', array: false },
    parentVariantCourse: { type: 'Course', array: false },
    parents: { type: 'MessageEntity', array: true },
    priceBreakdowns: { type: 'PriceBreakdowns', array: false },
    priceConfigs: { type: 'PriceConfig', array: true },
    prices: { type: 'Prices', array: false },
    rates: { type: 'Rates', array: false },
    removedCount: { type: 'Int', array: false },
    reviewAverage: { type: 'Float', array: false },
    reviewCount: { type: 'Int', array: false },
    reviews: { type: 'Review', array: true },
    room: { type: 'String', array: false },
    scholarshipCode: { type: 'String', array: false },
    score: { type: 'String', array: false },
    searchable: { type: 'Boolean', array: false },
    season: { type: 'Season', array: false },
    seasonId: { type: 'ID', array: false },
    site: { type: 'Site', array: false },
    siteGradeLabels: { type: 'String', array: true },
    siteId: { type: 'ID', array: false },
    spotsLeftByDay: { type: 'JSON', array: false },
    startDate: { type: 'ISO8601Date', array: false },
    status: { type: 'CourseStatus', array: false },
    supplies: { type: 'String', array: true },
    teacher: { type: 'Teacher', array: false },
    teacherId: { type: 'ID', array: false },
    teamMaxCapacity: { type: 'Int', array: false },
    token: { type: 'String', array: false },
    usageBillingDate: { type: 'ISO8601Date', array: false },
    userCompanyRole: { type: 'MemberRole', array: false },
    userCourseRole: { type: 'MemberRole', array: false },
    userFamilyRole: { type: 'MemberRole', array: false },
    userSiteRole: { type: 'MemberRole', array: false },
    vendor: { type: 'Vendor', array: false },
    waitlistOpen: { type: 'Boolean', array: false },
    waitlistedCount: { type: 'Int', array: false }
  },
  CourseBehavior: {
    attendance: { type: 'Boolean', array: false },
    attendanceLink: { type: 'Boolean', array: false },
    billing: { type: 'Boolean', array: false },
    enrollmentAdd: { type: 'Boolean', array: false },
    enrollmentAddExistingStudent: { type: 'Boolean', array: false },
    enrollmentAddWithDiscount: { type: 'Boolean', array: false },
    enrollments: { type: 'Boolean', array: false },
    enrollmentsChangeSeasonPlan: { type: 'Boolean', array: false },
    enrollmentsChangeSubscription: { type: 'Boolean', array: false },
    enrollmentsCustomCharge: { type: 'Boolean', array: false },
    enrollmentsMove: { type: 'Boolean', array: false },
    enrollmentsPromote: { type: 'Boolean', array: false },
    enrollmentsReenroll: { type: 'Boolean', array: false },
    enrollmentsRefund: { type: 'Boolean', array: false },
    enrollmentsRemove: { type: 'Boolean', array: false },
    enrollmentsSetGroups: { type: 'Boolean', array: false },
    id: { type: 'ID', array: false },
    info: { type: 'Boolean', array: false },
    messages: { type: 'Boolean', array: false },
    options: { type: 'Boolean', array: false },
    reviews: { type: 'Boolean', array: false },
    studentUpdate: { type: 'Boolean', array: false }
  },
  CourseDay: {
    days: { type: 'String', array: true },
    finish: { type: 'String', array: false },
    start: { type: 'String', array: false }
  },
  CourseFeeBreakdown: {
    discountAmount: { type: 'Float', array: false },
    homeroomCharges: { type: 'Float', array: false },
    listPrice: { type: 'Float', array: false },
    listPriceBeforeDiscount: { type: 'Float', array: false },
    siteCharges: { type: 'Float', array: false },
    siteCreditCardFee: { type: 'Float', array: false },
    siteReceives: { type: 'Float', array: false },
    vendorCharges: { type: 'Float', array: false },
    vendorCourseCost: { type: 'Float', array: false },
    vendorCreditCardFee: { type: 'Float', array: false },
    vendorMaterialsFee: { type: 'Float', array: false },
    vendorReceives: { type: 'Float', array: false }
  },
  CourseFramework: {
    courseCardImage: { type: 'String', array: false },
    courseImage: { type: 'String', array: false },
    courseTags: { type: 'CourseTag', array: true },
    description: { type: 'String', array: false },
    id: { type: 'ID', array: false },
    lastUsedCourse: { type: 'Course', array: false },
    name: { type: 'String', array: false },
    public: { type: 'Boolean', array: false },
    reviewAverage: { type: 'Float', array: false },
    reviewCount: { type: 'Int', array: false },
    reviews: { type: 'Review', array: true },
    supplies: { type: 'String', array: true }
  },
  CourseOption: {
    checkout: { type: 'Boolean', array: false },
    choices: { type: 'Course', array: true },
    description: { type: 'String', array: false },
    id: { type: 'ID', array: false },
    kind: { type: 'CourseOptionKind', array: false },
    required: { type: 'Boolean', array: false },
    title: { type: 'String', array: false }
  },
  CourseSummaryReport: {
    data: { type: 'CourseSummaryReportRow', array: true },
    totalEnrollments: { type: 'Int', array: false },
    totalParentSpend: { type: 'Float', array: false },
    totalProviderEarnings: { type: 'Float', array: false },
    totalSchoolEarnings: { type: 'Float', array: false }
  },
  CourseSummaryReportRow: {
    course: { type: 'String', array: false },
    endDate: { type: 'ISO8601Date', array: false },
    enrollments: { type: 'Int', array: false },
    grades: { type: 'String', array: false },
    id: { type: 'String', array: false },
    listPrice: { type: 'Float', array: false },
    period: { type: 'String', array: false },
    provider: { type: 'String', array: false },
    providerEarnings: { type: 'Float', array: false },
    schoolEarnings: { type: 'Float', array: false },
    site: { type: 'String', array: false },
    startDate: { type: 'ISO8601Date', array: false }
  },
  CourseTag: {
    cardImgUrl: { type: 'String', array: false },
    heading2: { type: 'String', array: false },
    heroImgUrl: { type: 'String', array: false },
    id: { type: 'ID', array: false },
    name: { type: 'String', array: false },
    slug: { type: 'String', array: false }
  },
  CoursesResult: {
    draftTotal: { type: 'Int', array: false },
    items: { type: 'Course', array: true },
    requestedTotal: { type: 'Int', array: false },
    total: { type: 'Int', array: false },
    totals: { type: 'Int', array: true }
  },
  CreditCard: {
    brand: { type: 'CardBrand', array: false },
    expiration: { type: 'String', array: false },
    id: { type: 'ID', array: false },
    last4: { type: 'String', array: false },
    name: { type: 'String', array: false }
  },
  CustomPriceConfig: {
    amount: { type: 'Float', array: false },
    billingDate: { type: 'ISO8601Date', array: false },
    billingDescription: { type: 'String', array: false },
    description: { type: 'String', array: false },
    kind: { type: 'PriceConfigKind', array: false },
    purchaseDescription: { type: 'String', array: false },
    template: { type: 'Boolean', array: false },
    updateDescription: { type: 'String', array: false }
  },
  Discount: {
    amountOff: { type: 'Float', array: false },
    code: { type: 'ID', array: false },
    format: { type: 'DiscountFormat', array: false },
    id: { type: 'ID', array: false },
    rate: { type: 'Float', array: false }
  },
  DiscountDefinition: {
    active: { type: 'Boolean', array: false },
    amount: { type: 'Float', array: false },
    code: { type: 'String', array: false },
    courses: { type: 'Course', array: true },
    format: { type: 'DiscountDefinitionFormat', array: false },
    formattedActive: { type: 'String', array: false },
    formattedAmount: { type: 'String', array: false },
    formattedKind: { type: 'String', array: false },
    id: { type: 'ID', array: false },
    kind: { type: 'DiscountKind', array: false },
    owner: { type: 'WithName', array: false },
    scope: { type: 'DiscountScope', array: false },
    student: { type: 'Student', array: false },
    usesCount: { type: 'Int', array: false }
  },
  DiscountScope: {
    courseKinds: { type: 'CourseKind', array: true },
    courses: { type: 'ID', array: true },
    students: { type: 'ID', array: true }
  },
  DiscountUse: {
    amount: { type: 'Float', array: false },
    amountOff: { type: 'Float', array: false },
    cartRemovable: { type: 'Boolean', array: false },
    code: { type: 'String', array: false },
    discountDefinitionId: { type: 'ID', array: false },
    format: { type: 'DiscountFormat', array: false },
    formattedAmount: { type: 'String', array: false },
    id: { type: 'ID', array: false },
    isStudent: { type: 'Boolean', array: false },
    kind: { type: 'DiscountKind', array: false },
    oneOff: { type: 'Boolean', array: false },
    rate: { type: 'Float', array: false }
  },
  DomainAuth: {
    domain: { type: 'String', array: false },
    id: { type: 'ID', array: false },
    provider: { type: 'DomainOauthProvider', array: false }
  },
  Donation: {
    coverTxnFees: { type: 'Boolean', array: false },
    finalAmount: { type: 'Float', array: false },
    id: { type: 'ID', array: false },
    initialAmount: { type: 'Float', array: false },
    seasonId: { type: 'ID', array: false },
    site: { type: 'Site', array: false },
    txnFee: { type: 'Float', array: false }
  },
  DonationConfig: {
    charityName: { type: 'String', array: false },
    ein: { type: 'String', array: false },
    enabled: { type: 'Boolean', array: false },
    nonprofit: { type: 'Boolean', array: false },
    siteEin: { type: 'String', array: false }
  },
  DropInPrice: {
    price: { type: 'Float', array: false }
  },
  DropInPriceConfig: {
    billingDate: { type: 'ISO8601Date', array: false },
    billingDescription: { type: 'String', array: false },
    dates: { type: 'ISO8601Date', array: true },
    kind: { type: 'PriceConfigKind', array: false },
    purchaseDescription: { type: 'String', array: false },
    template: { type: 'Boolean', array: false },
    updateDescription: { type: 'String', array: false }
  },
  DropInRate: {
    homeroomFeeRoundingIncrementCents: { type: 'Int', array: false },
    rate: { type: 'Float', array: false }
  },
  EnrollingSoonSeason: {
    allCourses: { type: 'Course', array: true },
    approvedCourseKindGroups: { type: 'CourseKindGroup', array: true },
    blockOverlappingEnrollments: { type: 'Boolean', array: false },
    cfQuestions: { type: 'CfQuestion', array: true },
    completed: { type: 'Boolean', array: false },
    courseKindGroups: { type: 'CourseKindGroup', array: true },
    courses: { type: 'Course', array: true },
    coursesBegin: { type: 'ISO8601DateTime', array: false },
    coursesFinish: { type: 'ISO8601DateTime', array: false },
    discountDefinitions: { type: 'DiscountDefinition', array: true },
    discountDefinitionsQuery: { type: 'DiscountDefinitionsQueryResults', array: false },
    enrollmentCloses: { type: 'ISO8601DateTime', array: false },
    enrollmentOpens: { type: 'ISO8601DateTime', array: false },
    enrollmentPeriod: { type: 'String', array: false },
    formattedKind: { type: 'String', array: false },
    games: { type: 'Course', array: true },
    hasConfigurablePricing: { type: 'Boolean', array: false },
    hasCourses: { type: 'Boolean', array: false },
    hasDiscountDefinitions: { type: 'Boolean', array: false },
    hasFutureCourses: { type: 'Boolean', array: false },
    hasNonSeasonPricing: { type: 'Boolean', array: false },
    holidays: { type: 'ISO8601Date', array: true },
    homeroomRate: { type: 'Float', array: false },
    id: { type: 'ID', array: false },
    kind: { type: 'SeasonKind', array: false },
    loadMetrics: { type: 'SeasonLoadInfo', array: false },
    name: { type: 'String', array: false },
    note: { type: 'String', array: false },
    open: { type: 'Boolean', array: false },
    payoutConfig: { type: 'SitePayoutConfig', array: false },
    pendingPayoutsTotal: { type: 'Float', array: false },
    private: { type: 'Boolean', array: false },
    registrationDisplayConfig: { type: 'SeasonRegistrationDisplayConfig', array: false },
    registrationGroup: { type: 'String', array: false },
    seasonPeriod: { type: 'String', array: false },
    site: { type: 'Site', array: false },
    siteId: { type: 'ID', array: false },
    status: { type: 'SeasonStatus', array: false },
    timezone: { type: 'String', array: false },
    totalCourses: { type: 'Int', array: false },
    totalSpots: { type: 'Int', array: false },
    totalSpotsLeft: { type: 'Int', array: false },
    txnFeeRate: { type: 'Float', array: false },
    upcoming: { type: 'Boolean', array: false }
  },
  Enrollment: {
    added: { type: 'ISO8601Date', array: false },
    additionalDiscount: { type: 'Discount', array: false },
    affirmEnabled: { type: 'Boolean', array: false },
    ageAtStart: { type: 'Int', array: false },
    amount: { type: 'Float', array: false },
    athleticsConfig: { type: 'AthleticsConfig', array: false },
    balance: { type: 'Float', array: false },
    billPeriod: { type: 'String', array: false },
    billedAt: { type: 'ISO8601DateTime', array: false },
    billedDate: { type: 'ISO8601Date', array: false },
    billingDescription: { type: 'String', array: false },
    billingStartDate: { type: 'ISO8601Date', array: false },
    cfAnswers: { type: 'JSON', array: false },
    cfFormattedAnswers: { type: 'JSON', array: false },
    cfFormattedSeasonAnswers: { type: 'JSON', array: false },
    cfQuestions: { type: 'CfQuestion', array: true },
    cfSeasonAnswers: { type: 'JSON', array: false },
    childEnrollments: { type: 'Enrollment', array: true },
    childOptions: { type: 'Enrollment', array: true },
    company: { type: 'Company', array: false },
    contacts: { type: 'Contact', array: true },
    course: { type: 'Course', array: false },
    courseOption: { type: 'CourseOption', array: false },
    createdAt: { type: 'ISO8601DateTime', array: false },
    discount: { type: 'Discount', array: false },
    discountAmount: { type: 'Float', array: false },
    discountCodes: { type: 'String', array: true },
    discountId: { type: 'ID', array: false },
    discountUses: { type: 'DiscountUse', array: true },
    discountedPrice: { type: 'Float', array: false },
    dismissal: { type: 'String', array: false },
    endDate: { type: 'ISO8601Date', array: false },
    enrollmentOptions: { type: 'EnrollmentOption', array: true },
    enrollmentPriceTierUse: { type: 'EnrollmentPriceTierUse', array: false },
    family: { type: 'Family', array: false },
    firstPayment: { type: 'FirstPayment', array: false },
    formattedDays: { type: 'String', array: false },
    formattedDaysAndTimes: { type: 'String', array: false },
    formattedOtherEnrollments: { type: 'String', array: false },
    formattedTimes: { type: 'String', array: false },
    gradeAtStart: { type: 'String', array: false },
    groups: { type: 'String', array: true },
    id: { type: 'ID', array: false },
    inAgeRange: { type: 'Boolean', array: false },
    inGradeRange: { type: 'Boolean', array: false },
    invitationExpired: { type: 'Boolean', array: false },
    isChildEnrollment: { type: 'Boolean', array: false },
    isInvitation: { type: 'Boolean', array: false },
    isOption: { type: 'Boolean', array: false },
    isOptionParent: { type: 'Boolean', array: false },
    isParentEnrollment: { type: 'Boolean', array: false },
    isScholarshipHold: { type: 'Boolean', array: false },
    lateStart: { type: 'Boolean', array: false },
    listPrice: { type: 'Float', array: false },
    optionParent: { type: 'Enrollment', array: false },
    otherEnrollments: { type: 'Enrollment', array: true },
    overlaps: { type: 'Boolean', array: false },
    parent: { type: 'GuardedUser', array: false },
    parentEnrollment: { type: 'Enrollment', array: false },
    parents: { type: 'GuardedUser', array: true },
    paymentFailureReason: { type: 'String', array: false },
    paymentService: { type: 'PaymentService', array: false },
    paymentStatus: { type: 'PaymentStatus', array: false },
    pendingChange: { type: 'EnrollmentChange', array: false },
    pendingInstances: { type: 'Enrollment', array: true },
    perSessionRefunds: { type: 'Boolean', array: false },
    pickup: { type: 'String', array: false },
    pickupDropoffRequired: { type: 'Boolean', array: false },
    priceConfig: { type: 'PriceConfig', array: false },
    priceConfigs: { type: 'PriceConfig', array: true },
    priceTierUse: { type: 'PriceTierUse', array: false },
    purchaseAmount: { type: 'Float', array: false },
    purchaseDescription: { type: 'String', array: false },
    purchased: { type: 'Boolean', array: false },
    refunds: { type: 'Refund', array: true },
    refundsTotal: { type: 'Float', array: false },
    rosterAmount: { type: 'String', array: false },
    rosterPeriod: { type: 'String', array: false },
    rosterPeriods: { type: 'String', array: false },
    season: { type: 'Season', array: false },
    startDate: { type: 'ISO8601Date', array: false },
    status: { type: 'EnrollmentStatusEnum', array: false },
    student: { type: 'Student', array: false },
    studentSelectionRequired: { type: 'Boolean', array: false },
    templateId: { type: 'String', array: false },
    trackingParameters: { type: 'String', array: false },
    waitlistKind: { type: 'WaitlistKind', array: false },
    weekday: { type: 'String', array: false },
    withdrawable: { type: 'Boolean', array: false }
  },
  EnrollmentAddWarning: {
    code: { type: 'String', array: false },
    course: { type: 'Course', array: false },
    message: { type: 'String', array: false },
    student: { type: 'Student', array: false }
  },
  EnrollmentChange: {
    discountedPrice: { type: 'Float', array: false },
    id: { type: 'ID', array: false },
    listPrice: { type: 'Float', array: false },
    priceConfig: { type: 'PriceConfig', array: false },
    startDate: { type: 'ISO8601Date', array: false }
  },
  EnrollmentDatesWillChangeOutput: {
    id: { type: 'ID', array: false },
    name: { type: 'String', array: false },
    result: { type: 'Boolean', array: false }
  },
  EnrollmentOption: {
    choices: { type: 'Enrollment', array: true },
    option: { type: 'CourseOption', array: false }
  },
  EnrollmentPriceTierUse: {
    cartPriceTierUse: { type: 'PriceTierUse', array: false },
    id: { type: 'ID', array: false }
  },
  Event: {
    contact: { type: 'GuardedUser', array: false },
    createdAt: { type: 'ISO8601DateTime', array: false },
    eventAttributes: { type: 'JSON', array: false },
    eventName: { type: 'String', array: false },
    id: { type: 'ID', array: false },
    recipient: { type: 'String', array: false },
    serviceType: { type: 'EventService', array: false }
  },
  Family: {
    attendances: { type: 'Attendance', array: true },
    attendancesQuery: { type: 'AttendancesQueryResults', array: false },
    ccContacts: { type: 'Contact', array: true },
    contacts: { type: 'Contact', array: true },
    creditCard: { type: 'CreditCard', array: false },
    enrollments: { type: 'Enrollment', array: true },
    enrollmentsQuery: { type: 'EnrollmentsQueryResults', array: false },
    hasAttendances: { type: 'Boolean', array: false },
    hasPaymentMethod: { type: 'Boolean', array: false },
    id: { type: 'ID', array: false },
    invoice: { type: 'Boolean', array: false },
    name: { type: 'String', array: false },
    parents: { type: 'GuardedUser', array: true },
    participants: { type: 'Participant', array: true },
    participantsQuery: { type: 'ParticipantsQueryResults', array: false },
    receipts: { type: 'Receipt', array: true },
    students: { type: 'Student', array: true },
    teamAdmins: { type: 'Member', array: true },
    teamAdminsQuery: { type: 'TeamAdminsQueryResults', array: false },
    teamChangeRole: { type: 'Boolean', array: false },
    teamInvites: { type: 'MemberInvitation', array: true },
    teamMembers: { type: 'Member', array: true },
    teamMembersQuery: { type: 'TeamMembersQueryResults', array: false },
    teamParticipantsUpsert: { type: 'Boolean', array: false },
    teamSetAsContact: { type: 'Boolean', array: false },
    usBankAccount: { type: 'UsBankAccount', array: false },
    user: { type: 'User', array: false },
    userCompanyRole: { type: 'MemberRole', array: false },
    userCourseRole: { type: 'MemberRole', array: false },
    userFamilyRole: { type: 'MemberRole', array: false },
    userMemberRole: { type: 'MemberRole', array: false },
    userSiteRole: { type: 'MemberRole', array: false }
  },
  FileUpload: {
    createdAt: { type: 'ISO8601DateTime', array: false },
    fileType: { type: 'String', array: false },
    id: { type: 'ID', array: false },
    name: { type: 'String', array: false },
    uri: { type: 'String', array: false }
  },
  FirstPayment: {
    amount: { type: 'Float', array: false },
    dueOn: { type: 'ISO8601Date', array: false },
    nextPaymentOn: { type: 'ISO8601Date', array: false },
    prorated: { type: 'Boolean', array: false },
    recurringAmount: { type: 'Float', array: false },
    recurringListPrice: { type: 'Float', array: false },
    refund: { type: 'Float', array: false }
  },
  Grade: {
    hidden: { type: 'Boolean', array: false },
    label: { type: 'String', array: false },
    value: { type: 'String', array: false }
  },
  GuardedUser: {
    attendanceCodeCode: { type: 'String', array: false },
    ccContacts: { type: 'Contact', array: true },
    email: { type: 'String', array: false },
    firstName: { type: 'String', array: false },
    formattedCcContacts: { type: 'String', array: false },
    id: { type: 'ID', array: false },
    lastName: { type: 'String', array: false },
    name: { type: 'String', array: false },
    phone: { type: 'String', array: false }
  },
  HistoryVersion: {
    attributes: { type: 'JSON', array: false },
    createdAt: { type: 'ISO8601DateTime', array: false },
    id: { type: 'ID', array: false },
    type: { type: 'String', array: false },
    user: { type: 'SharedUser', array: false }
  },
  Homeroom: {
    domainAuths: { type: 'DomainAuth', array: true },
    hrStripeBalance: { type: 'Float', array: false },
    id: { type: 'ID', array: false },
    pendingPayoutBalance: { type: 'Float', array: false },
    stripeBalance: { type: 'Float', array: false },
    transactionTotals: { type: 'HomeroomTransactionTotals', array: false }
  },
  HomeroomTransactionTotals: {
    costOfRevenue: { type: 'Float', array: false },
    grossProfit: { type: 'Float', array: false },
    netRevenue: { type: 'Float', array: false }
  },
  Image: {
    aspectRatio: { type: 'Float', array: false },
    assetId: { type: 'String', array: false },
    createdAt: { type: 'String', array: false },
    etag: { type: 'String', array: false },
    filename: { type: 'String', array: false },
    folder: { type: 'String', array: false },
    format: { type: 'String', array: false },
    height: { type: 'Int', array: false },
    pixels: { type: 'Int', array: false },
    publicId: { type: 'String', array: false },
    resourceType: { type: 'String', array: false },
    secureUrl: { type: 'String', array: false },
    status: { type: 'String', array: false },
    tags: { type: 'String', array: true },
    type: { type: 'String', array: false },
    uploadedAt: { type: 'String', array: false },
    url: { type: 'String', array: false },
    version: { type: 'Int', array: false },
    width: { type: 'Int', array: false }
  },
  Invite: {
    invitedTo: { type: 'String', array: false },
    kind: { type: 'InviteKind', array: false },
    token: { type: 'String', array: false }
  },
  Location: {
    city: { type: 'String', array: false },
    state: { type: 'String', array: false },
    timezone: { type: 'String', array: false }
  },
  Medication: {
    dose: { type: 'String', array: false },
    id: { type: 'ID', array: false },
    medication: { type: 'String', array: false },
    notes: { type: 'String', array: false }
  },
  Member: {
    attendanceCodeCode: { type: 'String', array: false },
    email: { type: 'String', array: false },
    firstName: { type: 'String', array: false },
    id: { type: 'ID', array: false },
    invitedAt: { type: 'ISO8601Date', array: false },
    isContact: { type: 'Boolean', array: false },
    isCurrentUser: { type: 'Boolean', array: false },
    kind: { type: 'ParticipantKind', array: false },
    lastName: { type: 'String', array: false },
    name: { type: 'String', array: false },
    normalizedRole: { type: 'MemberRole', array: false },
    owner: { type: 'WithName', array: false },
    phone: { type: 'String', array: false },
    position: { type: 'String', array: false },
    relation: { type: 'String', array: false },
    role: { type: 'String', array: false },
    user: { type: 'GuardedUser', array: false }
  },
  MemberInvitation: {
    attendanceCodeCode: { type: 'String', array: false },
    email: { type: 'String', array: false },
    firstName: { type: 'String', array: false },
    id: { type: 'ID', array: false },
    kind: { type: 'ParticipantKind', array: false },
    lastName: { type: 'String', array: false },
    name: { type: 'String', array: false },
    normalizedRole: { type: 'MemberRole', array: false },
    owner: { type: 'WithName', array: false },
    phone: { type: 'String', array: false },
    position: { type: 'String', array: false },
    relation: { type: 'String', array: false },
    role: { type: 'String', array: false },
    sentAt: { type: 'ISO8601Date', array: false },
    token: { type: 'String', array: false }
  },
  Message: {
    bcc: { type: 'MessageRecipient', array: true },
    body: { type: 'MessageContent', array: false },
    broadcast: { type: 'Boolean', array: false },
    cc: { type: 'MessageRecipient', array: true },
    createdAt: { type: 'ISO8601DateTime', array: false },
    entityMember: { type: 'Boolean', array: false },
    from: { type: 'MessageSender', array: false },
    id: { type: 'ID', array: false },
    inReplyTo: { type: 'Message', array: false },
    labels: { type: 'MessageEntity', array: true },
    participants: { type: 'MessageEntity', array: true },
    preferredService: { type: 'MessageServiceKind', array: false },
    read: { type: 'Boolean', array: false },
    subject: { type: 'String', array: false },
    systemMessage: { type: 'Boolean', array: false },
    threadId: { type: 'String', array: false },
    threadScope: { type: 'ID', array: false },
    to: { type: 'MessageRecipient', array: true },
    type: { type: 'String', array: false },
    updatedAt: { type: 'ISO8601DateTime', array: false }
  },
  MessageCommon: {
    bcc: { type: 'MessageRecipient', array: true },
    body: { type: 'MessageContent', array: false },
    broadcast: { type: 'Boolean', array: false },
    cc: { type: 'MessageRecipient', array: true },
    createdAt: { type: 'ISO8601DateTime', array: false },
    entityMember: { type: 'Boolean', array: false },
    from: { type: 'MessageSender', array: false },
    id: { type: 'ID', array: false },
    inReplyTo: { type: 'Message', array: false },
    labels: { type: 'MessageEntity', array: true },
    participants: { type: 'MessageEntity', array: true },
    preferredService: { type: 'MessageServiceKind', array: false },
    read: { type: 'Boolean', array: false },
    subject: { type: 'String', array: false },
    systemMessage: { type: 'Boolean', array: false },
    threadId: { type: 'String', array: false },
    threadScope: { type: 'ID', array: false },
    to: { type: 'MessageRecipient', array: true },
    type: { type: 'String', array: false },
    updatedAt: { type: 'ISO8601DateTime', array: false },
    messageCount: { type: 'Int', array: false },
    messages: { type: 'Message', array: true },
    preview: { type: 'String', array: false }
  },
  MessageContent: {
    html: { type: 'String', array: false },
    text: { type: 'String', array: false }
  },
  MessageEntity: {
    email: { type: 'String', array: false },
    entity: { type: 'MessageEntity', array: false },
    firstName: { type: 'String', array: false },
    firstNameWithNickname: { type: 'String', array: false },
    id: { type: 'ID', array: false },
    lastName: { type: 'String', array: false },
    name: { type: 'String', array: false }
  },
  MessageRecipient: {
    emailStatus: { type: 'MessageServiceDeliveryStatus', array: false },
    role: { type: 'String', array: false },
    smsStatus: { type: 'MessageServiceDeliveryStatus', array: false },
    students: { type: 'MessageEntity', array: true },
    to: { type: 'SharedUser', array: false },
    toEntity: { type: 'MessageEntity', array: false },
    toKind: { type: 'MessageEntity', array: false }
  },
  MessageSender: {
    from: { type: 'SharedUser', array: false },
    fromEntity: { type: 'MessageEntity', array: false },
    role: { type: 'String', array: false },
    students: { type: 'MessageEntity', array: true }
  },
  MessageThread: {
    bcc: { type: 'MessageRecipient', array: true },
    broadcast: { type: 'Boolean', array: false },
    cc: { type: 'MessageRecipient', array: true },
    createdAt: { type: 'ISO8601DateTime', array: false },
    entityMember: { type: 'Boolean', array: false },
    from: { type: 'MessageSender', array: false },
    id: { type: 'ID', array: false },
    labels: { type: 'MessageEntity', array: true },
    messageCount: { type: 'Int', array: false },
    messages: { type: 'Message', array: true },
    participants: { type: 'MessageEntity', array: true },
    preferredService: { type: 'MessageServiceKind', array: false },
    preview: { type: 'String', array: false },
    read: { type: 'Boolean', array: false },
    subject: { type: 'String', array: false },
    systemMessage: { type: 'Boolean', array: false },
    to: { type: 'MessageRecipient', array: true },
    type: { type: 'String', array: false },
    updatedAt: { type: 'ISO8601DateTime', array: false }
  },
  MutationChange: {
    displayName: { type: 'String', array: false },
    end: { type: 'ISO8601DateTime', array: false },
    id: { type: 'ID', array: false },
    ip: { type: 'String', array: false },
    kind: { type: 'String', array: false },
    lr: { type: 'String', array: false },
    mutationId: { type: 'ID', array: false },
    name: { type: 'String', array: false },
    related: { type: 'MutationChange', array: true },
    start: { type: 'ISO8601DateTime', array: false },
    targetChanges: { type: 'JSON', array: false },
    targetId: { type: 'ID', array: false },
    targetType: { type: 'String', array: false },
    user: { type: 'SharedUser', array: false },
    variables: { type: 'JSON', array: false }
  },
  OpeningSeatsWithWaitlistOutput: {
    id: { type: 'ID', array: false },
    name: { type: 'String', array: false },
    result: { type: 'Boolean', array: false }
  },
  Option: {
    label: { type: 'String', array: false },
    value: { type: 'OptionValue', array: false }
  },
  Order: {
    canProcessNow: { type: 'Boolean', array: false },
    createdAt: { type: 'ISO8601DateTime', array: false },
    id: { type: 'ID', array: false },
    items: { type: 'OrderItem', array: true },
    paymentFailureReason: { type: 'String', array: false },
    status: { type: 'PaymentStatus', array: false },
    total: { type: 'Float', array: false }
  },
  OrderItem: {
    course: { type: 'Course', array: false },
    createdAt: { type: 'ISO8601DateTime', array: false },
    id: { type: 'ID', array: false },
    product: { type: 'OrderItemProduct', array: false },
    total: { type: 'Float', array: false }
  },
  OrganizerRedesign: {
    bannerSeenNewUi: { type: 'ISO8601DateTime', array: false },
    bannerSeenOldUi: { type: 'ISO8601DateTime', array: false },
    introSeenNewUi: { type: 'ISO8601DateTime', array: false },
    on: { type: 'Boolean', array: false },
    switchable: { type: 'Boolean', array: false }
  },
  ParentAttendanceConfig: {
    earlyCheckIn: { type: 'Boolean', array: false },
    enabled: { type: 'Boolean', array: false },
    id: { type: 'ID', array: false },
    notify: { type: 'Boolean', array: false }
  },
  ParentAttendanceStudentsResult: {
    items: { type: 'RosterItem', array: true },
    owner: { type: 'Contact', array: false }
  },
  ParentRedesign: {
    introSeenNewUi: { type: 'ISO8601DateTime', array: false },
    on: { type: 'Boolean', array: false }
  },
  ParentSetAttendanceResult: {
    action: { type: 'AttendanceAction', array: false },
    id: { type: 'ID', array: false }
  },
  Participant: {
    attendanceCodeCode: { type: 'String', array: false },
    email: { type: 'String', array: false },
    firstName: { type: 'String', array: false },
    id: { type: 'ID', array: false },
    kind: { type: 'ParticipantKind', array: false },
    lastName: { type: 'String', array: false },
    name: { type: 'String', array: false },
    normalizedRole: { type: 'MemberRole', array: false },
    owner: { type: 'WithName', array: false },
    phone: { type: 'String', array: false },
    position: { type: 'String', array: false },
    relation: { type: 'String', array: false },
    role: { type: 'String', array: false },
    invitedAt: { type: 'ISO8601Date', array: false },
    isContact: { type: 'Boolean', array: false },
    isCurrentUser: { type: 'Boolean', array: false },
    user: { type: 'GuardedUser', array: false },
    sentAt: { type: 'ISO8601Date', array: false },
    token: { type: 'String', array: false }
  },
  Payout: {
    amount: { type: 'Float', array: false },
    createdAt: { type: 'ISO8601Date', array: false },
    id: { type: 'ID', array: false },
    item: { type: 'Payout', array: false },
    items: { type: 'Receipt', array: true },
    lastError: { type: 'String', array: false },
    paused: { type: 'Boolean', array: false },
    paymentServiceId: { type: 'String', array: false },
    scheduledAt: { type: 'ISO8601Date', array: false },
    sites: { type: 'Site', array: true },
    status: { type: 'String', array: false },
    target: { type: 'SiteOrCompany', array: false }
  },
  Person: {
    attendanceCodeCode: { type: 'String', array: false },
    email: { type: 'String', array: false },
    firstName: { type: 'String', array: false },
    id: { type: 'ID', array: false },
    kind: { type: 'ParticipantKind', array: false },
    lastName: { type: 'String', array: false },
    name: { type: 'String', array: false },
    normalizedRole: { type: 'MemberRole', array: false },
    owner: { type: 'WithName', array: false },
    phone: { type: 'String', array: false },
    position: { type: 'String', array: false },
    relation: { type: 'String', array: false },
    role: { type: 'String', array: false },
    ccContacts: { type: 'Contact', array: true },
    formattedCcContacts: { type: 'String', array: false },
    invitedAt: { type: 'ISO8601Date', array: false },
    isContact: { type: 'Boolean', array: false },
    isCurrentUser: { type: 'Boolean', array: false },
    user: { type: 'GuardedUser', array: false },
    sentAt: { type: 'ISO8601Date', array: false },
    token: { type: 'String', array: false },
    accessibleCourses: { type: 'Course', array: true },
    accessibleCoursesQuery: { type: 'AccessibleCoursesQueryResults', array: false },
    betaFlags: { type: 'BetaFlags', array: false },
    cart: { type: 'Cart', array: false },
    companies: { type: 'Company', array: true },
    companiesQuery: { type: 'CompaniesQueryResults', array: false },
    courseKindGroups: { type: 'CourseKindGroup', array: true },
    deactivated: { type: 'Boolean', array: false },
    deactivatedAt: { type: 'ISO8601DateTime', array: false },
    deletedAt: { type: 'ISO8601DateTime', array: false },
    enrollments: { type: 'Enrollment', array: true },
    enrollmentsQuery: { type: 'EnrollmentsQueryResults', array: false },
    events: { type: 'Event', array: true },
    eventsQuery: { type: 'EventsQueryResults', array: false },
    families: { type: 'Family', array: true },
    familiesQuery: { type: 'FamiliesQueryResults', array: false },
    files: { type: 'FileUpload', array: true },
    hasAccessibleCourses: { type: 'Boolean', array: false },
    invites: { type: 'Invite', array: true },
    joined: { type: 'ISO8601DateTime', array: false },
    orders: { type: 'Order', array: true },
    personalCalendar: { type: 'String', array: false },
    receipts: { type: 'Receipt', array: true },
    refunds: { type: 'Refund', array: true },
    roles: { type: 'UserRoles', array: false },
    sites: { type: 'Site', array: true },
    sitesQuery: { type: 'SitesQueryResults', array: false },
    students: { type: 'Student', array: true },
    tosAccepted: { type: 'Boolean', array: false },
    uuid: { type: 'String', array: false },
    vendorInvites: { type: 'Vendor', array: true }
  },
  Policy: {
    bathroom: { type: 'String', array: false },
    classroom: { type: 'String', array: false },
    id: { type: 'ID', array: false },
    lateParent: { type: 'String', array: false },
    missingStudent: { type: 'String', array: false },
    parentWithdrawalsEnabled: { type: 'Boolean', array: false },
    parking: { type: 'String', array: false },
    schoolRules: { type: 'String', array: false },
    site: { type: 'Site', array: false },
    siteId: { type: 'Int', array: false },
    snacks: { type: 'String', array: false },
    studentDismissal: { type: 'String', array: false },
    studentPickup: { type: 'String', array: false },
    teacherCheckin: { type: 'String', array: false },
    token: { type: 'String', array: false }
  },
  PriceBreakdown: {
    discountAmount: { type: 'Float', array: false },
    homeroomCharges: { type: 'Float', array: false },
    listPrice: { type: 'Float', array: false },
    listPriceBeforeDiscount: { type: 'Float', array: false },
    price: { type: 'Float', array: false },
    priceBeforeDiscount: { type: 'Float', array: false },
    siteCharges: { type: 'Float', array: false },
    siteCreditCardFee: { type: 'Float', array: false },
    siteReceives: { type: 'Float', array: false },
    vendorCharges: { type: 'Float', array: false },
    vendorCreditCardFee: { type: 'Float', array: false },
    vendorReceives: { type: 'Float', array: false }
  },
  PriceBreakdowns: {
    dropIn: { type: 'PriceBreakdown', array: false },
    recurring: { type: 'RecurringPriceBreakdown', array: true },
    season: { type: 'PriceBreakdown', array: false },
    seasons: { type: 'SeasonPriceBreakdown', array: true },
    usage: { type: 'UsagePriceBreakdown', array: true }
  },
  PriceConfig: {
    amount: { type: 'Float', array: false },
    billingDate: { type: 'ISO8601Date', array: false },
    billingDescription: { type: 'String', array: false },
    description: { type: 'String', array: false },
    kind: { type: 'PriceConfigKind', array: false },
    purchaseDescription: { type: 'String', array: false },
    template: { type: 'Boolean', array: false },
    updateDescription: { type: 'String', array: false },
    dates: { type: 'ISO8601Date', array: true },
    startDate: { type: 'ISO8601Date', array: false },
    startDateDisabled: { type: 'Boolean', array: false },
    unit: { type: 'RecurringUnit', array: false },
    weekdays: { type: 'String', array: true },
    deposit: { type: 'Boolean', array: false },
    depositAmount: { type: 'Float', array: false },
    installmentDates: { type: 'ISO8601Date', array: true },
    usingInstallments: { type: 'Boolean', array: false },
    attendance: { type: 'Attendance', array: false },
    formattedNonBillableTime: { type: 'String', array: false },
    formattedOverlapHandling: { type: 'String', array: false },
    formattedOverlappingSessionTimes: { type: 'String', array: false },
    gracePeriod: { type: 'Int', array: false },
    listRate: { type: 'Float', array: false },
    overlapHandling: { type: 'OverlapHandling', array: false },
    rate: { type: 'Float', array: false },
    roundingIncrement: { type: 'Int', array: false },
    sessionEnd: { type: 'String', array: false },
    sessionStart: { type: 'String', array: false },
    timeBilled: { type: 'String', array: false },
    timeInCourse: { type: 'String', array: false },
    unitsBilled: { type: 'Float', array: false }
  },
  PriceTier: {
    displayOrder: { type: 'Int', array: false },
    id: { type: 'ID', array: false },
    name: { type: 'String', array: false },
    rate: { type: 'Float', array: false }
  },
  PriceTierUse: {
    amountOff: { type: 'Float', array: false },
    displayOrder: { type: 'Int', array: false },
    id: { type: 'ID', array: false },
    name: { type: 'String', array: false },
    priceTierId: { type: 'ID', array: false },
    rate: { type: 'Float', array: false }
  },
  Prices: {
    dropIn: { type: 'DropInPrice', array: false },
    recurring: { type: 'RecurringPrice', array: true },
    season: { type: 'SeasonPrice', array: false },
    seasons: { type: 'SeasonPrice', array: true },
    usage: { type: 'UsagePrice', array: true }
  },
  ProrationConfig: {
    enrollmentEndMidMonth: { type: 'Boolean', array: false },
    enrollmentStartMidMonth: { type: 'Boolean', array: false },
    noActivityDays: { type: 'Boolean', array: false }
  },
  Rates: {
    dropIn: { type: 'DropInRate', array: false },
    recurring: { type: 'RecurringRate', array: true },
    season: { type: 'SeasonRate', array: false },
    seasons: { type: 'SeasonRate', array: true },
    usage: { type: 'UsageRate', array: true }
  },
  Receipt: {
    createdAt: { type: 'ISO8601DateTime', array: false },
    id: { type: 'ID', array: false },
    total: { type: 'Float', array: false },
    canProcessNow: { type: 'Boolean', array: false },
    items: { type: 'OrderItem', array: true },
    paymentFailureReason: { type: 'String', array: false },
    status: { type: 'PaymentStatus', array: false },
    course: { type: 'Course', array: false },
    product: { type: 'OrderItemProduct', array: false },
    amount: { type: 'Float', array: false },
    description: { type: 'String', array: false },
    enrollment: { type: 'Enrollment', array: false },
    initiatedBy: { type: 'SharedUser', array: false },
    item: { type: 'OrderItem', array: false },
    order: { type: 'Order', array: false },
    parent: { type: 'SharedUser', array: false },
    pending: { type: 'Boolean', array: false },
    remainder: { type: 'Float', array: false },
    student: { type: 'Student', array: false },
    void: { type: 'Boolean', array: false }
  },
  RecurringPrice: {
    days: { type: 'Int', array: false },
    price: { type: 'Float', array: false },
    unit: { type: 'RecurringUnit', array: false }
  },
  RecurringPriceBreakdown: {
    days: { type: 'Int', array: false },
    discountAmount: { type: 'Float', array: false },
    homeroomCharges: { type: 'Float', array: false },
    listPrice: { type: 'Float', array: false },
    listPriceBeforeDiscount: { type: 'Float', array: false },
    price: { type: 'Float', array: false },
    priceBeforeDiscount: { type: 'Float', array: false },
    siteCharges: { type: 'Float', array: false },
    siteCreditCardFee: { type: 'Float', array: false },
    siteReceives: { type: 'Float', array: false },
    unit: { type: 'RecurringUnit', array: false },
    vendorCharges: { type: 'Float', array: false },
    vendorCreditCardFee: { type: 'Float', array: false },
    vendorReceives: { type: 'Float', array: false }
  },
  RecurringPriceConfig: {
    billingDate: { type: 'ISO8601Date', array: false },
    billingDescription: { type: 'String', array: false },
    kind: { type: 'PriceConfigKind', array: false },
    purchaseDescription: { type: 'String', array: false },
    startDate: { type: 'ISO8601Date', array: false },
    startDateDisabled: { type: 'Boolean', array: false },
    template: { type: 'Boolean', array: false },
    unit: { type: 'RecurringUnit', array: false },
    updateDescription: { type: 'String', array: false },
    weekdays: { type: 'String', array: true }
  },
  RecurringRate: {
    days: { type: 'Int', array: false },
    homeroomFeeRoundingIncrementCents: { type: 'Int', array: false },
    rate: { type: 'Float', array: false },
    startDateDisabled: { type: 'Boolean', array: false },
    unit: { type: 'RecurringUnit', array: false }
  },
  Refund: {
    amount: { type: 'Float', array: false },
    course: { type: 'Course', array: false },
    createdAt: { type: 'ISO8601DateTime', array: false },
    description: { type: 'String', array: false },
    enrollment: { type: 'Enrollment', array: false },
    id: { type: 'ID', array: false },
    initiatedBy: { type: 'SharedUser', array: false },
    item: { type: 'OrderItem', array: false },
    order: { type: 'Order', array: false },
    parent: { type: 'SharedUser', array: false },
    pending: { type: 'Boolean', array: false },
    remainder: { type: 'Float', array: false },
    status: { type: 'PaymentStatus', array: false },
    student: { type: 'Student', array: false },
    total: { type: 'Float', array: false },
    void: { type: 'Boolean', array: false }
  },
  RefundBreakdown: {
    enrollment: { type: 'Enrollment', array: false },
    familyReceives: { type: 'Float', array: false },
    nonrefundableSiteFee: { type: 'Float', array: false },
    otherFeeReversals: { type: 'Float', array: false },
    providerWithdrawAmount: { type: 'Float', array: false },
    siteWithdrawAmount: { type: 'Float', array: false }
  },
  ReportResult: {
    count: { type: 'Int', array: false },
    items: { type: 'ReportTransaction', array: true },
    pageSize: { type: 'Int', array: false },
    total: { type: 'Int', array: false },
    totals: { type: 'Float', array: true }
  },
  ReportTransaction: {
    billPeriod: { type: 'String', array: false },
    company: { type: 'Company', array: false },
    companyEarnings: { type: 'Float', array: false },
    companyExternalTransferAmount: { type: 'Float', array: false },
    companyExternalTransferDate: { type: 'ISO8601Date', array: false },
    companyExternalTransferEstimatedDate: { type: 'ISO8601Date', array: false },
    companyExternalTransferStatus: { type: 'String', array: false },
    companyFee: { type: 'Float', array: false },
    companyHomeroomFee: { type: 'Float', array: false },
    companyInternalTransferDate: { type: 'ISO8601DateTime', array: false },
    companyProcessingFee: { type: 'Float', array: false },
    course: { type: 'Course', array: false },
    coversProcessingFee: { type: 'Boolean', array: false },
    discountAmount: { type: 'Float', array: false },
    discountCodes: { type: 'String', array: true },
    discountedCompanyFee: { type: 'Float', array: false },
    enrollmentPriceTierUse: { type: 'EnrollmentPriceTierUse', array: false },
    id: { type: 'ID', array: false },
    listPrice: { type: 'Float', array: false },
    parent: { type: 'SharedUser', array: false },
    paymentService: { type: 'PaymentService', array: false },
    rosterPeriod: { type: 'String', array: false },
    season: { type: 'Season', array: false },
    site: { type: 'Site', array: false },
    siteEarnings: { type: 'Float', array: false },
    siteExternalTransferAmount: { type: 'Float', array: false },
    siteExternalTransferDate: { type: 'ISO8601Date', array: false },
    siteExternalTransferEstimatedDate: { type: 'ISO8601Date', array: false },
    siteExternalTransferStatus: { type: 'String', array: false },
    siteFee: { type: 'Float', array: false },
    siteInternalTransferDate: { type: 'ISO8601DateTime', array: false },
    siteProcessingFee: { type: 'Float', array: false },
    student: { type: 'Student', array: false },
    trackingParameters: { type: 'String', array: false },
    transactionAmount: { type: 'Float', array: false },
    transactionDate: { type: 'ISO8601DateTime', array: false },
    transactionDescription: { type: 'String', array: false },
    transactionNote: { type: 'String', array: false },
    transactionStatus: { type: 'String', array: false }
  },
  Review: {
    comments: { type: 'String', array: false },
    company: { type: 'Company', array: false },
    course: { type: 'Course', array: false },
    createdAt: { type: 'ISO8601DateTime', array: false },
    enrollment: { type: 'Enrollment', array: false },
    id: { type: 'ID', array: false },
    instructorRating: { type: 'Int', array: false },
    overallRating: { type: 'Int', array: false },
    parent: { type: 'SharedUser', array: false },
    qualityRating: { type: 'Int', array: false },
    site: { type: 'Site', array: false },
    status: { type: 'String', array: false },
    student: { type: 'Student', array: false },
    title: { type: 'String', array: false }
  },
  RosterItem: {
    added: { type: 'ISO8601Date', array: false },
    additionalDiscount: { type: 'Discount', array: false },
    affirmEnabled: { type: 'Boolean', array: false },
    ageAtStart: { type: 'Int', array: false },
    amount: { type: 'Float', array: false },
    athleticsConfig: { type: 'AthleticsConfig', array: false },
    balance: { type: 'Float', array: false },
    billPeriod: { type: 'String', array: false },
    billedAt: { type: 'ISO8601DateTime', array: false },
    billedDate: { type: 'ISO8601Date', array: false },
    billingDescription: { type: 'String', array: false },
    billingStartDate: { type: 'ISO8601Date', array: false },
    cfAnswers: { type: 'JSON', array: false },
    cfFormattedAnswers: { type: 'JSON', array: false },
    cfFormattedSeasonAnswers: { type: 'JSON', array: false },
    cfQuestions: { type: 'CfQuestion', array: true },
    cfSeasonAnswers: { type: 'JSON', array: false },
    checkedIn: { type: 'CheckedIn', array: false },
    checkedInAt: { type: 'ISO8601DateTime', array: false },
    checkedInBy: { type: 'String', array: false },
    checkedOut: { type: 'Boolean', array: false },
    checkedOutAt: { type: 'ISO8601DateTime', array: false },
    checkedOutBy: { type: 'String', array: false },
    checkoutDest: { type: 'String', array: false },
    childEnrollments: { type: 'Enrollment', array: true },
    childOptions: { type: 'Enrollment', array: true },
    company: { type: 'Company', array: false },
    contacts: { type: 'Contact', array: true },
    course: { type: 'Course', array: false },
    courseOption: { type: 'CourseOption', array: false },
    createdAt: { type: 'ISO8601DateTime', array: false },
    discount: { type: 'Discount', array: false },
    discountAmount: { type: 'Float', array: false },
    discountCodes: { type: 'String', array: true },
    discountId: { type: 'ID', array: false },
    discountUses: { type: 'DiscountUse', array: true },
    discountedPrice: { type: 'Float', array: false },
    dismissal: { type: 'String', array: false },
    endDate: { type: 'ISO8601Date', array: false },
    endsAt: { type: 'ISO8601DateTime', array: false },
    enrollmentOptions: { type: 'EnrollmentOption', array: true },
    enrollmentPriceTierUse: { type: 'EnrollmentPriceTierUse', array: false },
    family: { type: 'Family', array: false },
    firstPayment: { type: 'FirstPayment', array: false },
    formattedDays: { type: 'String', array: false },
    formattedDaysAndTimes: { type: 'String', array: false },
    formattedEndsAt: { type: 'String', array: false },
    formattedOtherEnrollments: { type: 'String', array: false },
    formattedStartsAt: { type: 'String', array: false },
    formattedTimes: { type: 'String', array: false },
    gradeAtStart: { type: 'String', array: false },
    groups: { type: 'String', array: true },
    id: { type: 'ID', array: false },
    inAgeRange: { type: 'Boolean', array: false },
    inGradeRange: { type: 'Boolean', array: false },
    invitationExpired: { type: 'Boolean', array: false },
    isChildEnrollment: { type: 'Boolean', array: false },
    isInvitation: { type: 'Boolean', array: false },
    isOption: { type: 'Boolean', array: false },
    isOptionParent: { type: 'Boolean', array: false },
    isParentEnrollment: { type: 'Boolean', array: false },
    isScholarshipHold: { type: 'Boolean', array: false },
    lateStart: { type: 'Boolean', array: false },
    listPrice: { type: 'Float', array: false },
    optionParent: { type: 'Enrollment', array: false },
    otherEnrollments: { type: 'Enrollment', array: true },
    overlaps: { type: 'Boolean', array: false },
    parent: { type: 'GuardedUser', array: false },
    parentEnrollment: { type: 'Enrollment', array: false },
    parents: { type: 'GuardedUser', array: true },
    paymentFailureReason: { type: 'String', array: false },
    paymentService: { type: 'PaymentService', array: false },
    paymentStatus: { type: 'PaymentStatus', array: false },
    pendingChange: { type: 'EnrollmentChange', array: false },
    pendingInstances: { type: 'Enrollment', array: true },
    perSessionRefunds: { type: 'Boolean', array: false },
    pickup: { type: 'String', array: false },
    pickupDropoffRequired: { type: 'Boolean', array: false },
    priceConfig: { type: 'PriceConfig', array: false },
    priceConfigs: { type: 'PriceConfig', array: true },
    priceTierUse: { type: 'PriceTierUse', array: false },
    purchaseAmount: { type: 'Float', array: false },
    purchaseDescription: { type: 'String', array: false },
    purchased: { type: 'Boolean', array: false },
    refunds: { type: 'Refund', array: true },
    refundsTotal: { type: 'Float', array: false },
    rosterAmount: { type: 'String', array: false },
    rosterPeriod: { type: 'String', array: false },
    rosterPeriods: { type: 'String', array: false },
    season: { type: 'Season', array: false },
    startDate: { type: 'ISO8601Date', array: false },
    startsAt: { type: 'ISO8601DateTime', array: false },
    status: { type: 'EnrollmentStatusEnum', array: false },
    student: { type: 'Student', array: false },
    studentSelectionRequired: { type: 'Boolean', array: false },
    templateId: { type: 'String', array: false },
    trackingParameters: { type: 'String', array: false },
    updatedAt: { type: 'ISO8601DateTime', array: false },
    waitlistKind: { type: 'WaitlistKind', array: false },
    weekday: { type: 'String', array: false },
    withdrawable: { type: 'Boolean', array: false }
  },
  RosterResult: {
    items: { type: 'RosterItem', array: true },
    total: { type: 'Int', array: false }
  },
  Season: {
    allCourses: { type: 'Course', array: true },
    approvedCourseKindGroups: { type: 'CourseKindGroup', array: true },
    blockOverlappingEnrollments: { type: 'Boolean', array: false },
    cfQuestions: { type: 'CfQuestion', array: true },
    completed: { type: 'Boolean', array: false },
    courseKindGroups: { type: 'CourseKindGroup', array: true },
    courses: { type: 'Course', array: true },
    coursesBegin: { type: 'ISO8601DateTime', array: false },
    coursesFinish: { type: 'ISO8601DateTime', array: false },
    discountDefinitions: { type: 'DiscountDefinition', array: true },
    discountDefinitionsQuery: { type: 'DiscountDefinitionsQueryResults', array: false },
    enrollmentCloses: { type: 'ISO8601DateTime', array: false },
    enrollmentOpens: { type: 'ISO8601DateTime', array: false },
    formattedKind: { type: 'String', array: false },
    games: { type: 'Course', array: true },
    hasConfigurablePricing: { type: 'Boolean', array: false },
    hasCourses: { type: 'Boolean', array: false },
    hasDiscountDefinitions: { type: 'Boolean', array: false },
    hasFutureCourses: { type: 'Boolean', array: false },
    hasNonSeasonPricing: { type: 'Boolean', array: false },
    holidays: { type: 'ISO8601Date', array: true },
    homeroomRate: { type: 'Float', array: false },
    id: { type: 'ID', array: false },
    kind: { type: 'SeasonKind', array: false },
    name: { type: 'String', array: false },
    note: { type: 'String', array: false },
    open: { type: 'Boolean', array: false },
    payoutConfig: { type: 'SitePayoutConfig', array: false },
    pendingPayoutsTotal: { type: 'Float', array: false },
    private: { type: 'Boolean', array: false },
    registrationDisplayConfig: { type: 'SeasonRegistrationDisplayConfig', array: false },
    registrationGroup: { type: 'String', array: false },
    site: { type: 'Site', array: false },
    siteId: { type: 'ID', array: false },
    status: { type: 'SeasonStatus', array: false },
    txnFeeRate: { type: 'Float', array: false },
    upcoming: { type: 'Boolean', array: false }
  },
  SeasonLoadInfo: {
    enrollmentsInFifteen: { type: 'Int', array: false },
    enrollmentsInFive: { type: 'Int', array: false },
    enrollmentsInTen: { type: 'Int', array: false },
    id: { type: 'ID', array: false },
    name: { type: 'String', array: false },
    purchasedPerOrder: { type: 'Float', array: false },
    totalCourses: { type: 'Int', array: false },
    totalEnrollments: { type: 'Int', array: false },
    totalSpots: { type: 'Int', array: false },
    waitlistedEnrollments: { type: 'Int', array: false }
  },
  SeasonPrice: {
    days: { type: 'Int', array: false },
    price: { type: 'Float', array: false }
  },
  SeasonPriceBreakdown: {
    days: { type: 'Int', array: false },
    discountAmount: { type: 'Float', array: false },
    homeroomCharges: { type: 'Float', array: false },
    listPrice: { type: 'Float', array: false },
    listPriceBeforeDiscount: { type: 'Float', array: false },
    price: { type: 'Float', array: false },
    priceBeforeDiscount: { type: 'Float', array: false },
    siteCharges: { type: 'Float', array: false },
    siteCreditCardFee: { type: 'Float', array: false },
    siteReceives: { type: 'Float', array: false },
    vendorCharges: { type: 'Float', array: false },
    vendorCreditCardFee: { type: 'Float', array: false },
    vendorReceives: { type: 'Float', array: false }
  },
  SeasonPriceConfig: {
    billingDate: { type: 'ISO8601Date', array: false },
    billingDescription: { type: 'String', array: false },
    deposit: { type: 'Boolean', array: false },
    depositAmount: { type: 'Float', array: false },
    installmentDates: { type: 'ISO8601Date', array: true },
    kind: { type: 'PriceConfigKind', array: false },
    purchaseDescription: { type: 'String', array: false },
    template: { type: 'Boolean', array: false },
    updateDescription: { type: 'String', array: false },
    usingInstallments: { type: 'Boolean', array: false },
    weekdays: { type: 'String', array: true }
  },
  SeasonRate: {
    days: { type: 'Int', array: false },
    depositAmount: { type: 'Float', array: false },
    homeroomFeeRoundingIncrementCents: { type: 'Int', array: false },
    installmentDates: { type: 'ISO8601Date', array: true },
    materialsRate: { type: 'Float', array: false },
    rate: { type: 'Float', array: false }
  },
  SeasonRegistrationDisplayConfig: {
    groups: { type: 'String', array: true },
    layout: { type: 'SeasonRegistrationLayout', array: false },
    showDates: { type: 'Boolean', array: false },
    sorts: { type: 'String', array: true },
    tab: { type: 'String', array: false }
  },
  SharedUser: {
    email: { type: 'String', array: false },
    firstName: { type: 'String', array: false },
    id: { type: 'ID', array: false },
    lastName: { type: 'String', array: false },
    name: { type: 'String', array: false },
    phone: { type: 'String', array: false }
  },
  Site: {
    active: { type: 'Boolean', array: false },
    attendanceQrCode: { type: 'String', array: false },
    attendanceUrl: { type: 'String', array: false },
    blockOverlappingEnrollmentsDefault: { type: 'Boolean', array: false },
    branding: { type: 'SiteBranding', array: false },
    cartExpirationTimeMinutes: { type: 'Int', array: false },
    charityName: { type: 'String', array: false },
    checkoutDestinations: { type: 'String', array: true },
    city: { type: 'String', array: false },
    classrooms: { type: 'Classroom', array: true },
    complianceDefinitions: { type: 'ComplianceDefinitions', array: false },
    complianceReport: { type: 'ComplianceReportRow', array: true },
    contact: { type: 'Contact', array: false },
    contacts: { type: 'Contact', array: true },
    directoryCourseTags: { type: 'CourseTag', array: true },
    discountDefinitions: { type: 'DiscountDefinition', array: true },
    discountDefinitionsQuery: { type: 'DiscountDefinitionsQueryResults', array: false },
    donationConfig: { type: 'DonationConfig', array: false },
    familyParticipants: { type: 'Participant', array: true },
    familyParticipantsQuery: { type: 'FamilyParticipantsQueryResults', array: false },
    feeRate: { type: 'Float', array: false },
    feeType: { type: 'SiteFeeTypeEnum', array: false },
    gameSeasons: { type: 'Season', array: true },
    grades: { type: 'Grade', array: true },
    hasDiscountDefinitions: { type: 'Boolean', array: false },
    hasFamilyParticipants: { type: 'Boolean', array: false },
    hasMutations: { type: 'Boolean', array: false },
    hasSiteCompanies: { type: 'Boolean', array: false },
    hasTableViews: { type: 'Boolean', array: false },
    hasVendors: { type: 'Boolean', array: false },
    history: { type: 'HistoryVersion', array: true },
    homeroomRate: { type: 'Float', array: false },
    hubspotId: { type: 'String', array: false },
    id: { type: 'ID', array: false },
    latestSeason: { type: 'Season', array: false },
    mutationChanges: { type: 'MutationChange', array: true },
    mutationChangesQuery: { type: 'MutationChangesQueryResults', array: false },
    mutations: { type: 'Mutation', array: true },
    mutationsQuery: { type: 'MutationsQueryResults', array: false },
    name: { type: 'String', array: false },
    organizationType: { type: 'Organization', array: false },
    parentAttendanceConfig: { type: 'ParentAttendanceConfig', array: false },
    participants: { type: 'Participant', array: true },
    participantsQuery: { type: 'ParticipantsQueryResults', array: false },
    partner: { type: 'Boolean', array: false },
    payoutConfig: { type: 'SitePayoutConfig', array: false },
    payouts: { type: 'Payout', array: true },
    pendingRefunds: { type: 'Refund', array: true },
    phone: { type: 'String', array: false },
    pickupDestinations: { type: 'String', array: true },
    policy: { type: 'Policy', array: false },
    priceTiers: { type: 'PriceTierUse', array: true },
    processingRefunds: { type: 'Refund', array: true },
    proration: { type: 'ProrationKind', array: false },
    prorationConfig: { type: 'ProrationConfig', array: false },
    recentSeasons: { type: 'Season', array: true },
    registrationGroups: { type: 'String', array: true },
    restrictedAccountRegistration: { type: 'Boolean', array: false },
    retired: { type: 'Boolean', array: false },
    searchable: { type: 'Boolean', array: false },
    seasons: { type: 'Season', array: true },
    seasonsByStatus: { type: 'Season', array: true },
    siteCompanies: { type: 'Company', array: true },
    slug: { type: 'String', array: false },
    state: { type: 'String', array: false },
    streetAddress: { type: 'String', array: false },
    stripeAccount: { type: 'String', array: false },
    stripeAccountType: { type: 'String', array: false },
    stripeAccountVerified: { type: 'Boolean', array: false },
    stripeBalance: { type: 'Float', array: false },
    stripeCanIssueRefunds: { type: 'Boolean', array: false },
    stripeConnectUrl: { type: 'String', array: false },
    stripeDashboardUrl: { type: 'String', array: false },
    studentAssignableClassrooms: { type: 'Classroom', array: true },
    students: { type: 'Student', array: true },
    studentsQuery: { type: 'StudentsQueryResults', array: false },
    tableViews: { type: 'TableView', array: true },
    tableViewsQuery: { type: 'TableViewsQueryResults', array: false },
    tableViewsUpsert: { type: 'Boolean', array: false },
    tableViewsUpsertPersonal: { type: 'Boolean', array: false },
    tableviewsQuery: { type: 'TableViewsQueryResults', array: false },
    teamAdmins: { type: 'Member', array: true },
    teamAdminsQuery: { type: 'TeamAdminsQueryResults', array: false },
    teamChangeRole: { type: 'Boolean', array: false },
    teamInvites: { type: 'MemberInvitation', array: true },
    teamMembers: { type: 'Member', array: true },
    teamMembersQuery: { type: 'TeamMembersQueryResults', array: false },
    teamParticipantsUpsert: { type: 'Boolean', array: false },
    teamSetAsContact: { type: 'Boolean', array: false },
    timeEntryQrCode: { type: 'String', array: false },
    timeEntryUrl: { type: 'String', array: false },
    timezone: { type: 'String', array: false },
    timezoneDisplay: { type: 'String', array: false },
    trackingParameters: { type: 'SiteTrackingParameter', array: true },
    txnFeeRate: { type: 'Float', array: false },
    userCompanyRole: { type: 'MemberRole', array: false },
    userCourseRole: { type: 'MemberRole', array: false },
    userFamilyRole: { type: 'MemberRole', array: false },
    userMemberRole: { type: 'MemberRole', array: false },
    userSiteRole: { type: 'MemberRole', array: false },
    usingAch: { type: 'Boolean', array: false },
    usingAlipay: { type: 'Boolean', array: false },
    usingCompliance: { type: 'Boolean', array: false },
    usingNewRegistration: { type: 'Boolean', array: false },
    usingParentAttendance: { type: 'Boolean', array: false },
    usingPickups: { type: 'Boolean', array: false },
    usingPriceTiers: { type: 'Boolean', array: false },
    usingTrackingParameters: { type: 'Boolean', array: false },
    vendors: { type: 'Vendor', array: true },
    vendorsQuery: { type: 'VendorsQueryResults', array: false },
    waivers: { type: 'FileUpload', array: true },
    zipcode: { type: 'String', array: false }
  },
  SiteBranding: {
    id: { type: 'ID', array: false },
    logo: { type: 'String', array: false }
  },
  SiteDirectoryResult: {
    distance: { type: 'Int', array: false },
    site: { type: 'Site', array: false }
  },
  SiteOrCompany: {
    about: { type: 'String', array: false },
    address: { type: 'String', array: false },
    cancellationPolicy: { type: 'VendorGuideline', array: false },
    city: { type: 'String', array: false },
    courseCancellationPolicy: { type: 'String', array: false },
    courseFrameworks: { type: 'CourseFramework', array: true },
    courseFrameworksQuery: { type: 'CourseFrameworksQueryResults', array: false },
    courseKindGroups: { type: 'CourseKindGroup', array: true },
    courses: { type: 'Course', array: true },
    email: { type: 'String', array: false },
    hasCourseFrameworks: { type: 'Boolean', array: false },
    hasCourses: { type: 'Boolean', array: false },
    hasMutations: { type: 'Boolean', array: false },
    hasParticipants: { type: 'Boolean', array: false },
    hasRequests: { type: 'Boolean', array: false },
    hasTableViews: { type: 'Boolean', array: false },
    hasTeachers: { type: 'Boolean', array: false },
    hasTeamAdmins: { type: 'Boolean', array: false },
    hasTeamMembers: { type: 'Boolean', array: false },
    headline: { type: 'String', array: false },
    history: { type: 'HistoryVersion', array: true },
    id: { type: 'ID', array: false },
    mutationChanges: { type: 'MutationChange', array: true },
    mutationChangesQuery: { type: 'MutationChangesQueryResults', array: false },
    mutations: { type: 'Mutation', array: true },
    mutationsQuery: { type: 'MutationsQueryResults', array: false },
    name: { type: 'String', array: false },
    nonPartnerApproved: { type: 'Boolean', array: false },
    participants: { type: 'Participant', array: true },
    participantsQuery: { type: 'ParticipantsQueryResults', array: false },
    payouts: { type: 'Payout', array: true },
    pendingPayoutTotal: { type: 'Float', array: false },
    phone: { type: 'String', array: false },
    profileBackground: { type: 'String', array: false },
    profilePhoto: { type: 'String', array: false },
    quickStartGuide: { type: 'CompanyQuickStartGuide', array: false },
    sites: { type: 'Site', array: true },
    sitesQuery: { type: 'SitesQueryResults', array: false },
    size: { type: 'Int', array: false },
    slug: { type: 'String', array: false },
    state: { type: 'String', array: false },
    stripeAccount: { type: 'String', array: false },
    stripeAccountType: { type: 'String', array: false },
    stripeAccountVerified: { type: 'Boolean', array: false },
    stripeBalance: { type: 'Float', array: false },
    stripeCanIssueRefunds: { type: 'Boolean', array: false },
    stripeConnectUrl: { type: 'String', array: false },
    stripeDashboardUrl: { type: 'String', array: false },
    tableViews: { type: 'TableView', array: true },
    tableViewsQuery: { type: 'TableViewsQueryResults', array: false },
    tableViewsUpsert: { type: 'Boolean', array: false },
    tableViewsUpsertPersonal: { type: 'Boolean', array: false },
    teachers: { type: 'Teacher', array: true },
    teachersQuery: { type: 'TeachersQueryResults', array: false },
    teachingRadius: { type: 'Int', array: false },
    teamAdmins: { type: 'Member', array: true },
    teamAdminsQuery: { type: 'TeamAdminsQueryResults', array: false },
    teamChangeRole: { type: 'Boolean', array: false },
    teamInvites: { type: 'MemberInvitation', array: true },
    teamMembers: { type: 'Member', array: true },
    teamMembersQuery: { type: 'TeamMembersQueryResults', array: false },
    teamParticipantsUpsert: { type: 'Boolean', array: false },
    teamSetAsContact: { type: 'Boolean', array: false },
    timezone: { type: 'String', array: false },
    timezoneDisplay: { type: 'String', array: false },
    upcomingActiveOrRecentCourses: { type: 'Course', array: true },
    userCompanyRole: { type: 'MemberRole', array: false },
    userCourseRole: { type: 'MemberRole', array: false },
    userFamilyRole: { type: 'MemberRole', array: false },
    userMemberRole: { type: 'MemberRole', array: false },
    userSiteRole: { type: 'MemberRole', array: false },
    vendors: { type: 'Vendor', array: true },
    website: { type: 'String', array: false },
    zipcode: { type: 'String', array: false },
    active: { type: 'Boolean', array: false },
    attendanceQrCode: { type: 'String', array: false },
    attendanceUrl: { type: 'String', array: false },
    blockOverlappingEnrollmentsDefault: { type: 'Boolean', array: false },
    branding: { type: 'SiteBranding', array: false },
    cartExpirationTimeMinutes: { type: 'Int', array: false },
    charityName: { type: 'String', array: false },
    checkoutDestinations: { type: 'String', array: true },
    classrooms: { type: 'Classroom', array: true },
    complianceDefinitions: { type: 'ComplianceDefinitions', array: false },
    complianceReport: { type: 'ComplianceReportRow', array: true },
    contact: { type: 'Contact', array: false },
    contacts: { type: 'Contact', array: true },
    directoryCourseTags: { type: 'CourseTag', array: true },
    discountDefinitions: { type: 'DiscountDefinition', array: true },
    discountDefinitionsQuery: { type: 'DiscountDefinitionsQueryResults', array: false },
    donationConfig: { type: 'DonationConfig', array: false },
    familyParticipants: { type: 'Participant', array: true },
    familyParticipantsQuery: { type: 'FamilyParticipantsQueryResults', array: false },
    feeRate: { type: 'Float', array: false },
    feeType: { type: 'SiteFeeTypeEnum', array: false },
    gameSeasons: { type: 'Season', array: true },
    grades: { type: 'Grade', array: true },
    hasDiscountDefinitions: { type: 'Boolean', array: false },
    hasFamilyParticipants: { type: 'Boolean', array: false },
    hasSiteCompanies: { type: 'Boolean', array: false },
    hasVendors: { type: 'Boolean', array: false },
    homeroomRate: { type: 'Float', array: false },
    hubspotId: { type: 'String', array: false },
    latestSeason: { type: 'Season', array: false },
    organizationType: { type: 'Organization', array: false },
    parentAttendanceConfig: { type: 'ParentAttendanceConfig', array: false },
    partner: { type: 'Boolean', array: false },
    payoutConfig: { type: 'SitePayoutConfig', array: false },
    pendingRefunds: { type: 'Refund', array: true },
    pickupDestinations: { type: 'String', array: true },
    policy: { type: 'Policy', array: false },
    priceTiers: { type: 'PriceTierUse', array: true },
    processingRefunds: { type: 'Refund', array: true },
    proration: { type: 'ProrationKind', array: false },
    prorationConfig: { type: 'ProrationConfig', array: false },
    recentSeasons: { type: 'Season', array: true },
    registrationGroups: { type: 'String', array: true },
    restrictedAccountRegistration: { type: 'Boolean', array: false },
    retired: { type: 'Boolean', array: false },
    searchable: { type: 'Boolean', array: false },
    seasons: { type: 'Season', array: true },
    seasonsByStatus: { type: 'Season', array: true },
    siteCompanies: { type: 'Company', array: true },
    streetAddress: { type: 'String', array: false },
    studentAssignableClassrooms: { type: 'Classroom', array: true },
    students: { type: 'Student', array: true },
    studentsQuery: { type: 'StudentsQueryResults', array: false },
    tableviewsQuery: { type: 'TableViewsQueryResults', array: false },
    timeEntryQrCode: { type: 'String', array: false },
    timeEntryUrl: { type: 'String', array: false },
    trackingParameters: { type: 'SiteTrackingParameter', array: true },
    txnFeeRate: { type: 'Float', array: false },
    usingAch: { type: 'Boolean', array: false },
    usingAlipay: { type: 'Boolean', array: false },
    usingCompliance: { type: 'Boolean', array: false },
    usingNewRegistration: { type: 'Boolean', array: false },
    usingParentAttendance: { type: 'Boolean', array: false },
    usingPickups: { type: 'Boolean', array: false },
    usingPriceTiers: { type: 'Boolean', array: false },
    usingTrackingParameters: { type: 'Boolean', array: false },
    vendorsQuery: { type: 'VendorsQueryResults', array: false },
    waivers: { type: 'FileUpload', array: true }
  },
  SitePayoutConfig: {
    autoSiteCompanyPayouts: { type: 'Boolean', array: false },
    id: { type: 'ID', array: false },
    pause: { type: 'Boolean', array: false },
    timing: { type: 'PayoutTiming', array: false }
  },
  SiteTrackingParameter: {
    id: { type: 'ID', array: false },
    name: { type: 'String', array: false },
    value: { type: 'String', array: false }
  },
  StripePaymentToken: {
    completed: { type: 'Boolean', array: false },
    id: { type: 'String', array: false },
    token: { type: 'String', array: false }
  },
  Student: {
    age: { type: 'Int', array: false },
    attendances: { type: 'Attendance', array: true },
    attendancesQuery: { type: 'AttendancesQueryResults', array: false },
    authorizedPickupContacts: { type: 'Contact', array: true },
    automaticGradeUpdate: { type: 'Boolean', array: false },
    cfAnswers: { type: 'CfAnswer', array: true },
    cfAnswersCarted: { type: 'CfAnswer', array: true },
    cfAnswersConfirmed: { type: 'CfAnswer', array: true },
    cfQuestions: { type: 'CfQuestion', array: true },
    cfQuestionsCarted: { type: 'CfQuestion', array: true },
    cfQuestionsConfirmed: { type: 'CfQuestion', array: true },
    classroom: { type: 'Classroom', array: false },
    contacts: { type: 'Contact', array: true },
    dob: { type: 'ISO8601Date', array: false },
    emergencyContacts: { type: 'Contact', array: true },
    enrollmentCount: { type: 'Int', array: false },
    enrollments: { type: 'Enrollment', array: true },
    enrollmentsQuery: { type: 'EnrollmentsQueryResults', array: false },
    family: { type: 'Family', array: false },
    firstName: { type: 'String', array: false },
    firstNameWithNickname: { type: 'String', array: false },
    formattedAllergies: { type: 'String', array: false },
    formattedAuthorizedPickupContacts: { type: 'String', array: false },
    formattedContacts: { type: 'String', array: false },
    formattedEmergencyContacts: { type: 'String', array: false },
    formattedEpiPen: { type: 'String', array: false },
    formattedMedicalAndNotes: { type: 'String', array: false },
    formattedMedications: { type: 'String', array: false },
    grade: { type: 'String', array: false },
    id: { type: 'ID', array: false },
    lastName: { type: 'String', array: false },
    logEntries: { type: 'StudentLogEntry', array: true },
    medical: { type: 'StudentMedical', array: false },
    name: { type: 'String', array: false },
    nameWithNickname: { type: 'String', array: false },
    nickname: { type: 'String', array: false },
    notes: { type: 'String', array: false },
    parents: { type: 'GuardedUser', array: true },
    refunds: { type: 'Refund', array: true },
    site: { type: 'Site', array: false },
    siteGradeLabel: { type: 'String', array: false },
    upToDate: { type: 'Boolean', array: false }
  },
  StudentLogEntry: {
    createdBy: { type: 'SharedUser', array: false },
    date: { type: 'ISO8601DateTime', array: false },
    id: { type: 'ID', array: false },
    kind: { type: 'StudentLogEntryKind', array: false },
    notes: { type: 'String', array: false }
  },
  StudentMedical: {
    allergies: { type: 'Allergy', array: true },
    conditions: { type: 'String', array: false },
    epiPen: { type: 'Boolean', array: false },
    formattedAllergies: { type: 'String', array: false },
    formattedEpiPen: { type: 'String', array: false },
    formattedMedications: { type: 'String', array: false },
    id: { type: 'ID', array: false },
    medications: { type: 'Medication', array: true }
  },
  TableView: {
    advancedFilter: { type: 'JSON', array: false },
    cols: { type: 'TableViewColumn', array: true },
    filters: { type: 'TableViewFilter', array: true },
    groups: { type: 'TableViewGroup', array: true },
    id: { type: 'ID', array: false },
    lockedCol: { type: 'Int', array: false },
    name: { type: 'String', array: false },
    sorts: { type: 'TableViewSort', array: true },
    table: { type: 'String', array: false },
    updatedAt: { type: 'ISO8601DateTime', array: false },
    visibility: { type: 'TableViewVisibility', array: false }
  },
  TableViewColumn: {
    name: { type: 'String', array: false },
    width: { type: 'Float', array: false }
  },
  TableViewFilter: {
    name: { type: 'String', array: false },
    op: { type: 'FilterOperator', array: false },
    value: { type: 'OptionValue', array: true }
  },
  TableViewGroup: {
    direction: { type: 'SortDirection', array: false },
    name: { type: 'String', array: false }
  },
  TableViewSort: {
    direction: { type: 'SortDirection', array: false },
    name: { type: 'String', array: false }
  },
  Teacher: {
    email: { type: 'String', array: false },
    firstName: { type: 'String', array: false },
    id: { type: 'ID', array: false },
    lastName: { type: 'String', array: false },
    name: { type: 'String', array: false },
    phone: { type: 'String', array: false }
  },
  TimeEntry: {
    checkedIn: { type: 'ISO8601DateTime', array: false },
    checkedOut: { type: 'ISO8601DateTime', array: false },
    date: { type: 'ISO8601Date', array: false },
    formattedCheckedInTime: { type: 'String', array: false },
    formattedCheckedOutTime: { type: 'String', array: false },
    formattedDate: { type: 'String', array: false },
    hours: { type: 'Float', array: false },
    id: { type: 'ID', array: false },
    user: { type: 'GuardedUser', array: false }
  },
  UsBankAccount: {
    bankName: { type: 'String', array: false },
    fingerprint: { type: 'ID', array: false },
    last4: { type: 'String', array: false },
    routingNumber: { type: 'String', array: false }
  },
  UsagePrice: {
    price: { type: 'Float', array: false },
    unit: { type: 'UsageUnit', array: false }
  },
  UsagePriceBreakdown: {
    discountAmount: { type: 'Float', array: false },
    homeroomCharges: { type: 'Float', array: false },
    listPrice: { type: 'Float', array: false },
    listPriceBeforeDiscount: { type: 'Float', array: false },
    price: { type: 'Float', array: false },
    priceBeforeDiscount: { type: 'Float', array: false },
    siteCharges: { type: 'Float', array: false },
    siteCreditCardFee: { type: 'Float', array: false },
    siteReceives: { type: 'Float', array: false },
    unit: { type: 'UsageUnit', array: false },
    vendorCharges: { type: 'Float', array: false },
    vendorCreditCardFee: { type: 'Float', array: false },
    vendorReceives: { type: 'Float', array: false }
  },
  UsagePriceConfig: {
    attendance: { type: 'Attendance', array: false },
    billingDate: { type: 'ISO8601Date', array: false },
    billingDescription: { type: 'String', array: false },
    formattedNonBillableTime: { type: 'String', array: false },
    formattedOverlapHandling: { type: 'String', array: false },
    formattedOverlappingSessionTimes: { type: 'String', array: false },
    gracePeriod: { type: 'Int', array: false },
    kind: { type: 'PriceConfigKind', array: false },
    listRate: { type: 'Float', array: false },
    overlapHandling: { type: 'OverlapHandling', array: false },
    purchaseDescription: { type: 'String', array: false },
    rate: { type: 'Float', array: false },
    roundingIncrement: { type: 'Int', array: false },
    sessionEnd: { type: 'String', array: false },
    sessionStart: { type: 'String', array: false },
    template: { type: 'Boolean', array: false },
    timeBilled: { type: 'String', array: false },
    timeInCourse: { type: 'String', array: false },
    unit: { type: 'UsageUnit', array: false },
    unitsBilled: { type: 'Float', array: false },
    updateDescription: { type: 'String', array: false }
  },
  UsageRate: {
    gracePeriod: { type: 'Int', array: false },
    homeroomFeeRoundingIncrementCents: { type: 'Int', array: false },
    overlapHandling: { type: 'OverlapHandling', array: false },
    rate: { type: 'Float', array: false },
    roundingIncrement: { type: 'Int', array: false },
    unit: { type: 'UsageUnit', array: false }
  },
  User: {
    accessibleCourses: { type: 'Course', array: true },
    accessibleCoursesQuery: { type: 'AccessibleCoursesQueryResults', array: false },
    attendanceCodeCode: { type: 'String', array: false },
    betaFlags: { type: 'BetaFlags', array: false },
    cart: { type: 'Cart', array: false },
    ccContacts: { type: 'Contact', array: true },
    companies: { type: 'Company', array: true },
    companiesQuery: { type: 'CompaniesQueryResults', array: false },
    courseKindGroups: { type: 'CourseKindGroup', array: true },
    deactivated: { type: 'Boolean', array: false },
    deactivatedAt: { type: 'ISO8601DateTime', array: false },
    deletedAt: { type: 'ISO8601DateTime', array: false },
    email: { type: 'String', array: false },
    enrollments: { type: 'Enrollment', array: true },
    enrollmentsQuery: { type: 'EnrollmentsQueryResults', array: false },
    events: { type: 'Event', array: true },
    eventsQuery: { type: 'EventsQueryResults', array: false },
    families: { type: 'Family', array: true },
    familiesQuery: { type: 'FamiliesQueryResults', array: false },
    files: { type: 'FileUpload', array: true },
    firstName: { type: 'String', array: false },
    formattedCcContacts: { type: 'String', array: false },
    hasAccessibleCourses: { type: 'Boolean', array: false },
    id: { type: 'ID', array: false },
    invites: { type: 'Invite', array: true },
    joined: { type: 'ISO8601DateTime', array: false },
    lastName: { type: 'String', array: false },
    name: { type: 'String', array: false },
    orders: { type: 'Order', array: true },
    personalCalendar: { type: 'String', array: false },
    phone: { type: 'String', array: false },
    receipts: { type: 'Receipt', array: true },
    refunds: { type: 'Refund', array: true },
    roles: { type: 'UserRoles', array: false },
    sites: { type: 'Site', array: true },
    sitesQuery: { type: 'SitesQueryResults', array: false },
    students: { type: 'Student', array: true },
    tosAccepted: { type: 'Boolean', array: false },
    uuid: { type: 'String', array: false },
    vendorInvites: { type: 'Vendor', array: true }
  },
  UserRoles: {
    admin: { type: 'Boolean', array: false },
    company: { type: 'Company', array: false },
    course: { type: 'CourseRole', array: false },
    family: { type: 'Family', array: false },
    familyRole: { type: 'FamilyRole', array: false },
    organizer: { type: 'OrganizerRole', array: false },
    parent: { type: 'Boolean', array: false },
    provider: { type: 'CompanyRole', array: false },
    site: { type: 'Site', array: false }
  },
  Vendor: {
    accepted: { type: 'Boolean', array: false },
    cancellationPolicy: { type: 'VendorGuideline', array: false },
    company: { type: 'Company', array: false },
    companyName: { type: 'String', array: false },
    complianceRequirements: { type: 'ComplianceRequirement', array: true },
    contacts: { type: 'Contact', array: true },
    courseCancellationPolicy: { type: 'String', array: false },
    courses: { type: 'Course', array: true },
    displayName: { type: 'String', array: false },
    email: { type: 'String', array: false },
    id: { type: 'ID', array: false },
    inCompliance: { type: 'Boolean', array: false },
    name: { type: 'String', array: false },
    nickName: { type: 'String', array: false },
    phone: { type: 'String', array: false },
    showProviderCompliance: { type: 'Boolean', array: false },
    showTeacherCompliance: { type: 'Boolean', array: false },
    site: { type: 'Site', array: false },
    siteCompany: { type: 'Boolean', array: false },
    teachers: { type: 'Teacher', array: true },
    token: { type: 'String', array: false },
    userIsMember: { type: 'Boolean', array: false },
    vendorInCompliance: { type: 'Boolean', array: false },
    vendorTeachers: { type: 'VendorTeacher', array: true }
  },
  VendorGuideline: {
    id: { type: 'ID', array: false },
    text: { type: 'String', array: false }
  },
  VendorTeacher: {
    id: { type: 'ID', array: false },
    inCompliance: { type: 'Boolean', array: false },
    teacher: { type: 'Teacher', array: false }
  },
  WithDiscountDefinitions: {
    allCourses: { type: 'Course', array: true },
    approvedCourseKindGroups: { type: 'CourseKindGroup', array: true },
    blockOverlappingEnrollments: { type: 'Boolean', array: false },
    cfQuestions: { type: 'CfQuestion', array: true },
    completed: { type: 'Boolean', array: false },
    courseKindGroups: { type: 'CourseKindGroup', array: true },
    courses: { type: 'Course', array: true },
    coursesBegin: { type: 'ISO8601DateTime', array: false },
    coursesFinish: { type: 'ISO8601DateTime', array: false },
    discountDefinitions: { type: 'DiscountDefinition', array: true },
    discountDefinitionsQuery: { type: 'DiscountDefinitionsQueryResults', array: false },
    enrollmentCloses: { type: 'ISO8601DateTime', array: false },
    enrollmentOpens: { type: 'ISO8601DateTime', array: false },
    enrollmentPeriod: { type: 'String', array: false },
    formattedKind: { type: 'String', array: false },
    games: { type: 'Course', array: true },
    hasConfigurablePricing: { type: 'Boolean', array: false },
    hasCourses: { type: 'Boolean', array: false },
    hasDiscountDefinitions: { type: 'Boolean', array: false },
    hasFutureCourses: { type: 'Boolean', array: false },
    hasNonSeasonPricing: { type: 'Boolean', array: false },
    holidays: { type: 'ISO8601Date', array: true },
    homeroomRate: { type: 'Float', array: false },
    id: { type: 'ID', array: false },
    kind: { type: 'SeasonKind', array: false },
    loadMetrics: { type: 'SeasonLoadInfo', array: false },
    name: { type: 'String', array: false },
    note: { type: 'String', array: false },
    open: { type: 'Boolean', array: false },
    payoutConfig: { type: 'SitePayoutConfig', array: false },
    pendingPayoutsTotal: { type: 'Float', array: false },
    private: { type: 'Boolean', array: false },
    registrationDisplayConfig: { type: 'SeasonRegistrationDisplayConfig', array: false },
    registrationGroup: { type: 'String', array: false },
    seasonPeriod: { type: 'String', array: false },
    site: { type: 'Site', array: false },
    siteId: { type: 'ID', array: false },
    status: { type: 'SeasonStatus', array: false },
    timezone: { type: 'String', array: false },
    totalCourses: { type: 'Int', array: false },
    totalSpots: { type: 'Int', array: false },
    totalSpotsLeft: { type: 'Int', array: false },
    txnFeeRate: { type: 'Float', array: false },
    upcoming: { type: 'Boolean', array: false },
    active: { type: 'Boolean', array: false },
    attendanceQrCode: { type: 'String', array: false },
    attendanceUrl: { type: 'String', array: false },
    blockOverlappingEnrollmentsDefault: { type: 'Boolean', array: false },
    branding: { type: 'SiteBranding', array: false },
    cartExpirationTimeMinutes: { type: 'Int', array: false },
    charityName: { type: 'String', array: false },
    checkoutDestinations: { type: 'String', array: true },
    city: { type: 'String', array: false },
    classrooms: { type: 'Classroom', array: true },
    complianceDefinitions: { type: 'ComplianceDefinitions', array: false },
    complianceReport: { type: 'ComplianceReportRow', array: true },
    contact: { type: 'Contact', array: false },
    contacts: { type: 'Contact', array: true },
    directoryCourseTags: { type: 'CourseTag', array: true },
    donationConfig: { type: 'DonationConfig', array: false },
    familyParticipants: { type: 'Participant', array: true },
    familyParticipantsQuery: { type: 'FamilyParticipantsQueryResults', array: false },
    feeRate: { type: 'Float', array: false },
    feeType: { type: 'SiteFeeTypeEnum', array: false },
    gameSeasons: { type: 'Season', array: true },
    grades: { type: 'Grade', array: true },
    hasFamilyParticipants: { type: 'Boolean', array: false },
    hasMutations: { type: 'Boolean', array: false },
    hasSiteCompanies: { type: 'Boolean', array: false },
    hasTableViews: { type: 'Boolean', array: false },
    hasVendors: { type: 'Boolean', array: false },
    history: { type: 'HistoryVersion', array: true },
    hubspotId: { type: 'String', array: false },
    latestSeason: { type: 'Season', array: false },
    mutationChanges: { type: 'MutationChange', array: true },
    mutationChangesQuery: { type: 'MutationChangesQueryResults', array: false },
    mutations: { type: 'Mutation', array: true },
    mutationsQuery: { type: 'MutationsQueryResults', array: false },
    organizationType: { type: 'Organization', array: false },
    parentAttendanceConfig: { type: 'ParentAttendanceConfig', array: false },
    participants: { type: 'Participant', array: true },
    participantsQuery: { type: 'ParticipantsQueryResults', array: false },
    partner: { type: 'Boolean', array: false },
    payouts: { type: 'Payout', array: true },
    pendingRefunds: { type: 'Refund', array: true },
    phone: { type: 'String', array: false },
    pickupDestinations: { type: 'String', array: true },
    policy: { type: 'Policy', array: false },
    priceTiers: { type: 'PriceTierUse', array: true },
    processingRefunds: { type: 'Refund', array: true },
    proration: { type: 'ProrationKind', array: false },
    prorationConfig: { type: 'ProrationConfig', array: false },
    recentSeasons: { type: 'Season', array: true },
    registrationGroups: { type: 'String', array: true },
    restrictedAccountRegistration: { type: 'Boolean', array: false },
    retired: { type: 'Boolean', array: false },
    searchable: { type: 'Boolean', array: false },
    seasons: { type: 'Season', array: true },
    seasonsByStatus: { type: 'Season', array: true },
    siteCompanies: { type: 'Company', array: true },
    slug: { type: 'String', array: false },
    state: { type: 'String', array: false },
    streetAddress: { type: 'String', array: false },
    stripeAccount: { type: 'String', array: false },
    stripeAccountType: { type: 'String', array: false },
    stripeAccountVerified: { type: 'Boolean', array: false },
    stripeBalance: { type: 'Float', array: false },
    stripeCanIssueRefunds: { type: 'Boolean', array: false },
    stripeConnectUrl: { type: 'String', array: false },
    stripeDashboardUrl: { type: 'String', array: false },
    studentAssignableClassrooms: { type: 'Classroom', array: true },
    students: { type: 'Student', array: true },
    studentsQuery: { type: 'StudentsQueryResults', array: false },
    tableViews: { type: 'TableView', array: true },
    tableViewsQuery: { type: 'TableViewsQueryResults', array: false },
    tableViewsUpsert: { type: 'Boolean', array: false },
    tableViewsUpsertPersonal: { type: 'Boolean', array: false },
    tableviewsQuery: { type: 'TableViewsQueryResults', array: false },
    teamAdmins: { type: 'Member', array: true },
    teamAdminsQuery: { type: 'TeamAdminsQueryResults', array: false },
    teamChangeRole: { type: 'Boolean', array: false },
    teamInvites: { type: 'MemberInvitation', array: true },
    teamMembers: { type: 'Member', array: true },
    teamMembersQuery: { type: 'TeamMembersQueryResults', array: false },
    teamParticipantsUpsert: { type: 'Boolean', array: false },
    teamSetAsContact: { type: 'Boolean', array: false },
    timeEntryQrCode: { type: 'String', array: false },
    timeEntryUrl: { type: 'String', array: false },
    timezoneDisplay: { type: 'String', array: false },
    trackingParameters: { type: 'SiteTrackingParameter', array: true },
    userCompanyRole: { type: 'MemberRole', array: false },
    userCourseRole: { type: 'MemberRole', array: false },
    userFamilyRole: { type: 'MemberRole', array: false },
    userMemberRole: { type: 'MemberRole', array: false },
    userSiteRole: { type: 'MemberRole', array: false },
    usingAch: { type: 'Boolean', array: false },
    usingAlipay: { type: 'Boolean', array: false },
    usingCompliance: { type: 'Boolean', array: false },
    usingNewRegistration: { type: 'Boolean', array: false },
    usingParentAttendance: { type: 'Boolean', array: false },
    usingPickups: { type: 'Boolean', array: false },
    usingPriceTiers: { type: 'Boolean', array: false },
    usingTrackingParameters: { type: 'Boolean', array: false },
    vendors: { type: 'Vendor', array: true },
    vendorsQuery: { type: 'VendorsQueryResults', array: false },
    waivers: { type: 'FileUpload', array: true },
    zipcode: { type: 'String', array: false }
  },
  WithName: {
    about: { type: 'String', array: false },
    address: { type: 'String', array: false },
    cancellationPolicy: { type: 'VendorGuideline', array: false },
    city: { type: 'String', array: false },
    courseCancellationPolicy: { type: 'String', array: false },
    courseFrameworks: { type: 'CourseFramework', array: true },
    courseFrameworksQuery: { type: 'CourseFrameworksQueryResults', array: false },
    courseKindGroups: { type: 'CourseKindGroup', array: true },
    courses: { type: 'Course', array: true },
    email: { type: 'String', array: false },
    hasCourseFrameworks: { type: 'Boolean', array: false },
    hasCourses: { type: 'Boolean', array: false },
    hasMutations: { type: 'Boolean', array: false },
    hasParticipants: { type: 'Boolean', array: false },
    hasRequests: { type: 'Boolean', array: false },
    hasTableViews: { type: 'Boolean', array: false },
    hasTeachers: { type: 'Boolean', array: false },
    hasTeamAdmins: { type: 'Boolean', array: false },
    hasTeamMembers: { type: 'Boolean', array: false },
    headline: { type: 'String', array: false },
    history: { type: 'HistoryVersion', array: true },
    id: { type: 'ID', array: false },
    mutationChanges: { type: 'MutationChange', array: true },
    mutationChangesQuery: { type: 'MutationChangesQueryResults', array: false },
    mutations: { type: 'Mutation', array: true },
    mutationsQuery: { type: 'MutationsQueryResults', array: false },
    name: { type: 'String', array: false },
    nonPartnerApproved: { type: 'Boolean', array: false },
    participants: { type: 'Participant', array: true },
    participantsQuery: { type: 'ParticipantsQueryResults', array: false },
    payouts: { type: 'Payout', array: true },
    pendingPayoutTotal: { type: 'Float', array: false },
    phone: { type: 'String', array: false },
    profileBackground: { type: 'String', array: false },
    profilePhoto: { type: 'String', array: false },
    quickStartGuide: { type: 'CompanyQuickStartGuide', array: false },
    sites: { type: 'Site', array: true },
    sitesQuery: { type: 'SitesQueryResults', array: false },
    size: { type: 'Int', array: false },
    slug: { type: 'String', array: false },
    state: { type: 'String', array: false },
    stripeAccount: { type: 'String', array: false },
    stripeAccountType: { type: 'String', array: false },
    stripeAccountVerified: { type: 'Boolean', array: false },
    stripeBalance: { type: 'Float', array: false },
    stripeCanIssueRefunds: { type: 'Boolean', array: false },
    stripeConnectUrl: { type: 'String', array: false },
    stripeDashboardUrl: { type: 'String', array: false },
    tableViews: { type: 'TableView', array: true },
    tableViewsQuery: { type: 'TableViewsQueryResults', array: false },
    tableViewsUpsert: { type: 'Boolean', array: false },
    tableViewsUpsertPersonal: { type: 'Boolean', array: false },
    teachers: { type: 'Teacher', array: true },
    teachersQuery: { type: 'TeachersQueryResults', array: false },
    teachingRadius: { type: 'Int', array: false },
    teamAdmins: { type: 'Member', array: true },
    teamAdminsQuery: { type: 'TeamAdminsQueryResults', array: false },
    teamChangeRole: { type: 'Boolean', array: false },
    teamInvites: { type: 'MemberInvitation', array: true },
    teamMembers: { type: 'Member', array: true },
    teamMembersQuery: { type: 'TeamMembersQueryResults', array: false },
    teamParticipantsUpsert: { type: 'Boolean', array: false },
    teamSetAsContact: { type: 'Boolean', array: false },
    timezone: { type: 'String', array: false },
    timezoneDisplay: { type: 'String', array: false },
    upcomingActiveOrRecentCourses: { type: 'Course', array: true },
    userCompanyRole: { type: 'MemberRole', array: false },
    userCourseRole: { type: 'MemberRole', array: false },
    userFamilyRole: { type: 'MemberRole', array: false },
    userMemberRole: { type: 'MemberRole', array: false },
    userSiteRole: { type: 'MemberRole', array: false },
    vendors: { type: 'Vendor', array: true },
    website: { type: 'String', array: false },
    zipcode: { type: 'String', array: false },
    ageGroup: { type: 'String', array: false },
    ageMax: { type: 'Int', array: false },
    ageMin: { type: 'Int', array: false },
    allDaysFull: { type: 'Boolean', array: false },
    attendanceEnabled: { type: 'Boolean', array: false },
    autoCheckoutStudents: { type: 'Boolean', array: false },
    awayScore: { type: 'Int', array: false },
    awayTeam: { type: 'Course', array: false },
    behavior: { type: 'CourseBehavior', array: false },
    cancelledAt: { type: 'ISO8601DateTime', array: false },
    cartedCount: { type: 'Int', array: false },
    cfQuestions: { type: 'CfQuestion', array: true },
    childCourses: { type: 'Course', array: true },
    childOptionCourses: { type: 'Course', array: true },
    childVariantCourses: { type: 'Course', array: true },
    children: { type: 'Course', array: true },
    classesCount: { type: 'Int', array: false },
    closed: { type: 'Boolean', array: false },
    company: { type: 'Company', array: false },
    cost: { type: 'Float', array: false },
    courseCardImage: { type: 'String', array: false },
    courseDays: { type: 'CourseDay', array: true },
    courseDaysSortKey: { type: 'String', array: false },
    courseFramework: { type: 'CourseFramework', array: false },
    courseFrameworkId: { type: 'ID', array: false },
    courseImage: { type: 'String', array: false },
    courseTags: { type: 'CourseTag', array: true },
    dailyCapacity: { type: 'Boolean', array: false },
    days: { type: 'String', array: true },
    description: { type: 'String', array: false },
    disambiguatedName: { type: 'String', array: false },
    discardedAt: { type: 'ISO8601DateTime', array: false },
    effectiveSiteFeeRate: { type: 'Float', array: false },
    effectiveSiteFeeType: { type: 'SiteFeeTypeEnum', array: false },
    endDate: { type: 'ISO8601Date', array: false },
    enrolledCount: { type: 'Int', array: false },
    enrolledCountByDay: { type: 'JSON', array: false },
    enrollmentCloses: { type: 'ISO8601DateTime', array: false },
    enrollmentOpens: { type: 'ISO8601DateTime', array: false },
    enrollments: { type: 'Enrollment', array: true },
    enrollmentsQuery: { type: 'EnrollmentsQueryResults', array: false },
    events: { type: 'Event', array: true },
    eventsQuery: { type: 'EventsQueryResults', array: false },
    feeRate: { type: 'Float', array: false },
    feeType: { type: 'SiteFeeTypeEnum', array: false },
    forRosterWithInvitesCount: { type: 'Int', array: false },
    formattedCourseDays: { type: 'String', array: false },
    formattedDaysAndTimes: { type: 'String', array: false },
    formattedKind: { type: 'String', array: false },
    gradeGroup: { type: 'String', array: false },
    grades: { type: 'String', array: true },
    hasCheckoutOptions: { type: 'Boolean', array: false },
    hasEnrolledStudents: { type: 'Boolean', array: false },
    hasOptionParents: { type: 'Boolean', array: false },
    hasOptions: { type: 'Boolean', array: false },
    hasParentCourses: { type: 'Boolean', array: false },
    hasParentEnrollments: { type: 'Boolean', array: false },
    hasParentOptionCourses: { type: 'Boolean', array: false },
    homeScore: { type: 'Int', array: false },
    homeTeam: { type: 'Course', array: false },
    kind: { type: 'CourseKind', array: false },
    lastWithdrawDate: { type: 'ISO8601DateTime', array: false },
    listPrice: { type: 'Float', array: false },
    materialsFee: { type: 'Float', array: false },
    maxCapacity: { type: 'Int', array: false },
    messages: { type: 'Message', array: true },
    minCapacity: { type: 'Int', array: false },
    nameForRoster: { type: 'String', array: false },
    noEnrichmentDays: { type: 'ISO8601Date', array: true },
    notes: { type: 'String', array: false },
    optionParents: { type: 'Enrollment', array: true },
    optionParentsQuery: { type: 'OptionParentsQueryResults', array: false },
    options: { type: 'CourseOption', array: true },
    parent: { type: 'Course', array: false },
    parentCourses: { type: 'Course', array: true },
    parentEnrollments: { type: 'Enrollment', array: true },
    parentEnrollmentsQuery: { type: 'ParentEnrollmentsQueryResults', array: false },
    parentOptionCourses: { type: 'Course', array: true },
    parentVariant: { type: 'Course', array: false },
    parentVariantCourse: { type: 'Course', array: false },
    parents: { type: 'MessageEntity', array: true },
    priceBreakdowns: { type: 'PriceBreakdowns', array: false },
    priceConfigs: { type: 'PriceConfig', array: true },
    prices: { type: 'Prices', array: false },
    rates: { type: 'Rates', array: false },
    removedCount: { type: 'Int', array: false },
    reviewAverage: { type: 'Float', array: false },
    reviewCount: { type: 'Int', array: false },
    reviews: { type: 'Review', array: true },
    room: { type: 'String', array: false },
    scholarshipCode: { type: 'String', array: false },
    score: { type: 'String', array: false },
    searchable: { type: 'Boolean', array: false },
    season: { type: 'Season', array: false },
    seasonId: { type: 'ID', array: false },
    site: { type: 'Site', array: false },
    siteGradeLabels: { type: 'String', array: true },
    siteId: { type: 'ID', array: false },
    spotsLeftByDay: { type: 'JSON', array: false },
    startDate: { type: 'ISO8601Date', array: false },
    status: { type: 'CourseStatus', array: false },
    supplies: { type: 'String', array: true },
    teacher: { type: 'Teacher', array: false },
    teacherId: { type: 'ID', array: false },
    teamMaxCapacity: { type: 'Int', array: false },
    token: { type: 'String', array: false },
    usageBillingDate: { type: 'ISO8601Date', array: false },
    vendor: { type: 'Vendor', array: false },
    waitlistOpen: { type: 'Boolean', array: false },
    waitlistedCount: { type: 'Int', array: false },
    allCourses: { type: 'Course', array: true },
    approvedCourseKindGroups: { type: 'CourseKindGroup', array: true },
    blockOverlappingEnrollments: { type: 'Boolean', array: false },
    completed: { type: 'Boolean', array: false },
    coursesBegin: { type: 'ISO8601DateTime', array: false },
    coursesFinish: { type: 'ISO8601DateTime', array: false },
    discountDefinitions: { type: 'DiscountDefinition', array: true },
    discountDefinitionsQuery: { type: 'DiscountDefinitionsQueryResults', array: false },
    enrollmentPeriod: { type: 'String', array: false },
    games: { type: 'Course', array: true },
    hasConfigurablePricing: { type: 'Boolean', array: false },
    hasDiscountDefinitions: { type: 'Boolean', array: false },
    hasFutureCourses: { type: 'Boolean', array: false },
    hasNonSeasonPricing: { type: 'Boolean', array: false },
    holidays: { type: 'ISO8601Date', array: true },
    homeroomRate: { type: 'Float', array: false },
    loadMetrics: { type: 'SeasonLoadInfo', array: false },
    note: { type: 'String', array: false },
    open: { type: 'Boolean', array: false },
    payoutConfig: { type: 'SitePayoutConfig', array: false },
    pendingPayoutsTotal: { type: 'Float', array: false },
    private: { type: 'Boolean', array: false },
    registrationDisplayConfig: { type: 'SeasonRegistrationDisplayConfig', array: false },
    registrationGroup: { type: 'String', array: false },
    seasonPeriod: { type: 'String', array: false },
    totalCourses: { type: 'Int', array: false },
    totalSpots: { type: 'Int', array: false },
    totalSpotsLeft: { type: 'Int', array: false },
    txnFeeRate: { type: 'Float', array: false },
    upcoming: { type: 'Boolean', array: false },
    attendances: { type: 'Attendance', array: true },
    attendancesQuery: { type: 'AttendancesQueryResults', array: false },
    ccContacts: { type: 'Contact', array: true },
    contacts: { type: 'Contact', array: true },
    creditCard: { type: 'CreditCard', array: false },
    hasAttendances: { type: 'Boolean', array: false },
    hasPaymentMethod: { type: 'Boolean', array: false },
    invoice: { type: 'Boolean', array: false },
    receipts: { type: 'Receipt', array: true },
    students: { type: 'Student', array: true },
    usBankAccount: { type: 'UsBankAccount', array: false },
    user: { type: 'User', array: false },
    active: { type: 'Boolean', array: false },
    attendanceQrCode: { type: 'String', array: false },
    attendanceUrl: { type: 'String', array: false },
    blockOverlappingEnrollmentsDefault: { type: 'Boolean', array: false },
    branding: { type: 'SiteBranding', array: false },
    cartExpirationTimeMinutes: { type: 'Int', array: false },
    charityName: { type: 'String', array: false },
    checkoutDestinations: { type: 'String', array: true },
    classrooms: { type: 'Classroom', array: true },
    complianceDefinitions: { type: 'ComplianceDefinitions', array: false },
    complianceReport: { type: 'ComplianceReportRow', array: true },
    contact: { type: 'Contact', array: false },
    directoryCourseTags: { type: 'CourseTag', array: true },
    donationConfig: { type: 'DonationConfig', array: false },
    familyParticipants: { type: 'Participant', array: true },
    familyParticipantsQuery: { type: 'FamilyParticipantsQueryResults', array: false },
    gameSeasons: { type: 'Season', array: true },
    hasFamilyParticipants: { type: 'Boolean', array: false },
    hasSiteCompanies: { type: 'Boolean', array: false },
    hasVendors: { type: 'Boolean', array: false },
    hubspotId: { type: 'String', array: false },
    latestSeason: { type: 'Season', array: false },
    organizationType: { type: 'Organization', array: false },
    parentAttendanceConfig: { type: 'ParentAttendanceConfig', array: false },
    partner: { type: 'Boolean', array: false },
    pendingRefunds: { type: 'Refund', array: true },
    pickupDestinations: { type: 'String', array: true },
    policy: { type: 'Policy', array: false },
    priceTiers: { type: 'PriceTierUse', array: true },
    processingRefunds: { type: 'Refund', array: true },
    proration: { type: 'ProrationKind', array: false },
    prorationConfig: { type: 'ProrationConfig', array: false },
    recentSeasons: { type: 'Season', array: true },
    registrationGroups: { type: 'String', array: true },
    restrictedAccountRegistration: { type: 'Boolean', array: false },
    retired: { type: 'Boolean', array: false },
    seasons: { type: 'Season', array: true },
    seasonsByStatus: { type: 'Season', array: true },
    siteCompanies: { type: 'Company', array: true },
    streetAddress: { type: 'String', array: false },
    studentAssignableClassrooms: { type: 'Classroom', array: true },
    studentsQuery: { type: 'StudentsQueryResults', array: false },
    tableviewsQuery: { type: 'TableViewsQueryResults', array: false },
    timeEntryQrCode: { type: 'String', array: false },
    timeEntryUrl: { type: 'String', array: false },
    trackingParameters: { type: 'SiteTrackingParameter', array: true },
    usingAch: { type: 'Boolean', array: false },
    usingAlipay: { type: 'Boolean', array: false },
    usingCompliance: { type: 'Boolean', array: false },
    usingNewRegistration: { type: 'Boolean', array: false },
    usingParentAttendance: { type: 'Boolean', array: false },
    usingPickups: { type: 'Boolean', array: false },
    usingPriceTiers: { type: 'Boolean', array: false },
    usingTrackingParameters: { type: 'Boolean', array: false },
    vendorsQuery: { type: 'VendorsQueryResults', array: false },
    waivers: { type: 'FileUpload', array: true }
  },
  WithNameAndId: {
    about: { type: 'String', array: false },
    address: { type: 'String', array: false },
    cancellationPolicy: { type: 'VendorGuideline', array: false },
    city: { type: 'String', array: false },
    courseCancellationPolicy: { type: 'String', array: false },
    courseFrameworks: { type: 'CourseFramework', array: true },
    courseFrameworksQuery: { type: 'CourseFrameworksQueryResults', array: false },
    courseKindGroups: { type: 'CourseKindGroup', array: true },
    courses: { type: 'Course', array: true },
    email: { type: 'String', array: false },
    hasCourseFrameworks: { type: 'Boolean', array: false },
    hasCourses: { type: 'Boolean', array: false },
    hasMutations: { type: 'Boolean', array: false },
    hasParticipants: { type: 'Boolean', array: false },
    hasRequests: { type: 'Boolean', array: false },
    hasTableViews: { type: 'Boolean', array: false },
    hasTeachers: { type: 'Boolean', array: false },
    hasTeamAdmins: { type: 'Boolean', array: false },
    hasTeamMembers: { type: 'Boolean', array: false },
    headline: { type: 'String', array: false },
    history: { type: 'HistoryVersion', array: true },
    id: { type: 'ID', array: false },
    mutationChanges: { type: 'MutationChange', array: true },
    mutationChangesQuery: { type: 'MutationChangesQueryResults', array: false },
    mutations: { type: 'Mutation', array: true },
    mutationsQuery: { type: 'MutationsQueryResults', array: false },
    name: { type: 'String', array: false },
    nonPartnerApproved: { type: 'Boolean', array: false },
    participants: { type: 'Participant', array: true },
    participantsQuery: { type: 'ParticipantsQueryResults', array: false },
    payouts: { type: 'Payout', array: true },
    pendingPayoutTotal: { type: 'Float', array: false },
    phone: { type: 'String', array: false },
    profileBackground: { type: 'String', array: false },
    profilePhoto: { type: 'String', array: false },
    quickStartGuide: { type: 'CompanyQuickStartGuide', array: false },
    sites: { type: 'Site', array: true },
    sitesQuery: { type: 'SitesQueryResults', array: false },
    size: { type: 'Int', array: false },
    slug: { type: 'String', array: false },
    state: { type: 'String', array: false },
    stripeAccount: { type: 'String', array: false },
    stripeAccountType: { type: 'String', array: false },
    stripeAccountVerified: { type: 'Boolean', array: false },
    stripeBalance: { type: 'Float', array: false },
    stripeCanIssueRefunds: { type: 'Boolean', array: false },
    stripeConnectUrl: { type: 'String', array: false },
    stripeDashboardUrl: { type: 'String', array: false },
    tableViews: { type: 'TableView', array: true },
    tableViewsQuery: { type: 'TableViewsQueryResults', array: false },
    tableViewsUpsert: { type: 'Boolean', array: false },
    tableViewsUpsertPersonal: { type: 'Boolean', array: false },
    teachers: { type: 'Teacher', array: true },
    teachersQuery: { type: 'TeachersQueryResults', array: false },
    teachingRadius: { type: 'Int', array: false },
    teamAdmins: { type: 'Member', array: true },
    teamAdminsQuery: { type: 'TeamAdminsQueryResults', array: false },
    teamChangeRole: { type: 'Boolean', array: false },
    teamInvites: { type: 'MemberInvitation', array: true },
    teamMembers: { type: 'Member', array: true },
    teamMembersQuery: { type: 'TeamMembersQueryResults', array: false },
    teamParticipantsUpsert: { type: 'Boolean', array: false },
    teamSetAsContact: { type: 'Boolean', array: false },
    timezone: { type: 'String', array: false },
    timezoneDisplay: { type: 'String', array: false },
    upcomingActiveOrRecentCourses: { type: 'Course', array: true },
    userCompanyRole: { type: 'MemberRole', array: false },
    userCourseRole: { type: 'MemberRole', array: false },
    userFamilyRole: { type: 'MemberRole', array: false },
    userMemberRole: { type: 'MemberRole', array: false },
    userSiteRole: { type: 'MemberRole', array: false },
    vendors: { type: 'Vendor', array: true },
    website: { type: 'String', array: false },
    zipcode: { type: 'String', array: false },
    attendances: { type: 'Attendance', array: true },
    attendancesQuery: { type: 'AttendancesQueryResults', array: false },
    ccContacts: { type: 'Contact', array: true },
    contacts: { type: 'Contact', array: true },
    creditCard: { type: 'CreditCard', array: false },
    enrollments: { type: 'Enrollment', array: true },
    enrollmentsQuery: { type: 'EnrollmentsQueryResults', array: false },
    hasAttendances: { type: 'Boolean', array: false },
    hasPaymentMethod: { type: 'Boolean', array: false },
    invoice: { type: 'Boolean', array: false },
    parents: { type: 'GuardedUser', array: true },
    receipts: { type: 'Receipt', array: true },
    students: { type: 'Student', array: true },
    usBankAccount: { type: 'UsBankAccount', array: false },
    user: { type: 'User', array: false },
    active: { type: 'Boolean', array: false },
    attendanceQrCode: { type: 'String', array: false },
    attendanceUrl: { type: 'String', array: false },
    blockOverlappingEnrollmentsDefault: { type: 'Boolean', array: false },
    branding: { type: 'SiteBranding', array: false },
    cartExpirationTimeMinutes: { type: 'Int', array: false },
    charityName: { type: 'String', array: false },
    checkoutDestinations: { type: 'String', array: true },
    classrooms: { type: 'Classroom', array: true },
    complianceDefinitions: { type: 'ComplianceDefinitions', array: false },
    complianceReport: { type: 'ComplianceReportRow', array: true },
    contact: { type: 'Contact', array: false },
    directoryCourseTags: { type: 'CourseTag', array: true },
    discountDefinitions: { type: 'DiscountDefinition', array: true },
    discountDefinitionsQuery: { type: 'DiscountDefinitionsQueryResults', array: false },
    donationConfig: { type: 'DonationConfig', array: false },
    familyParticipants: { type: 'Participant', array: true },
    familyParticipantsQuery: { type: 'FamilyParticipantsQueryResults', array: false },
    feeRate: { type: 'Float', array: false },
    feeType: { type: 'SiteFeeTypeEnum', array: false },
    gameSeasons: { type: 'Season', array: true },
    grades: { type: 'Grade', array: true },
    hasDiscountDefinitions: { type: 'Boolean', array: false },
    hasFamilyParticipants: { type: 'Boolean', array: false },
    hasSiteCompanies: { type: 'Boolean', array: false },
    hasVendors: { type: 'Boolean', array: false },
    homeroomRate: { type: 'Float', array: false },
    hubspotId: { type: 'String', array: false },
    latestSeason: { type: 'Season', array: false },
    organizationType: { type: 'Organization', array: false },
    parentAttendanceConfig: { type: 'ParentAttendanceConfig', array: false },
    partner: { type: 'Boolean', array: false },
    payoutConfig: { type: 'SitePayoutConfig', array: false },
    pendingRefunds: { type: 'Refund', array: true },
    pickupDestinations: { type: 'String', array: true },
    policy: { type: 'Policy', array: false },
    priceTiers: { type: 'PriceTierUse', array: true },
    processingRefunds: { type: 'Refund', array: true },
    proration: { type: 'ProrationKind', array: false },
    prorationConfig: { type: 'ProrationConfig', array: false },
    recentSeasons: { type: 'Season', array: true },
    registrationGroups: { type: 'String', array: true },
    restrictedAccountRegistration: { type: 'Boolean', array: false },
    retired: { type: 'Boolean', array: false },
    searchable: { type: 'Boolean', array: false },
    seasons: { type: 'Season', array: true },
    seasonsByStatus: { type: 'Season', array: true },
    siteCompanies: { type: 'Company', array: true },
    streetAddress: { type: 'String', array: false },
    studentAssignableClassrooms: { type: 'Classroom', array: true },
    studentsQuery: { type: 'StudentsQueryResults', array: false },
    tableviewsQuery: { type: 'TableViewsQueryResults', array: false },
    timeEntryQrCode: { type: 'String', array: false },
    timeEntryUrl: { type: 'String', array: false },
    trackingParameters: { type: 'SiteTrackingParameter', array: true },
    txnFeeRate: { type: 'Float', array: false },
    usingAch: { type: 'Boolean', array: false },
    usingAlipay: { type: 'Boolean', array: false },
    usingCompliance: { type: 'Boolean', array: false },
    usingNewRegistration: { type: 'Boolean', array: false },
    usingParentAttendance: { type: 'Boolean', array: false },
    usingPickups: { type: 'Boolean', array: false },
    usingPriceTiers: { type: 'Boolean', array: false },
    usingTrackingParameters: { type: 'Boolean', array: false },
    vendorsQuery: { type: 'VendorsQueryResults', array: false },
    waivers: { type: 'FileUpload', array: true }
  },
  WithRoles: {
    ageGroup: { type: 'String', array: false },
    ageMax: { type: 'Int', array: false },
    ageMin: { type: 'Int', array: false },
    allDaysFull: { type: 'Boolean', array: false },
    attendanceEnabled: { type: 'Boolean', array: false },
    autoCheckoutStudents: { type: 'Boolean', array: false },
    awayScore: { type: 'Int', array: false },
    awayTeam: { type: 'Course', array: false },
    behavior: { type: 'CourseBehavior', array: false },
    cancelledAt: { type: 'ISO8601DateTime', array: false },
    cartedCount: { type: 'Int', array: false },
    cfQuestions: { type: 'CfQuestion', array: true },
    childCourses: { type: 'Course', array: true },
    childOptionCourses: { type: 'Course', array: true },
    childVariantCourses: { type: 'Course', array: true },
    children: { type: 'Course', array: true },
    classesCount: { type: 'Int', array: false },
    closed: { type: 'Boolean', array: false },
    company: { type: 'Company', array: false },
    cost: { type: 'Float', array: false },
    courseCardImage: { type: 'String', array: false },
    courseDays: { type: 'CourseDay', array: true },
    courseDaysSortKey: { type: 'String', array: false },
    courseFramework: { type: 'CourseFramework', array: false },
    courseFrameworkId: { type: 'ID', array: false },
    courseImage: { type: 'String', array: false },
    courseTags: { type: 'CourseTag', array: true },
    dailyCapacity: { type: 'Boolean', array: false },
    days: { type: 'String', array: true },
    description: { type: 'String', array: false },
    disambiguatedName: { type: 'String', array: false },
    discardedAt: { type: 'ISO8601DateTime', array: false },
    effectiveSiteFeeRate: { type: 'Float', array: false },
    effectiveSiteFeeType: { type: 'SiteFeeTypeEnum', array: false },
    endDate: { type: 'ISO8601Date', array: false },
    enrolledCount: { type: 'Int', array: false },
    enrolledCountByDay: { type: 'JSON', array: false },
    enrollmentCloses: { type: 'ISO8601DateTime', array: false },
    enrollmentOpens: { type: 'ISO8601DateTime', array: false },
    enrollments: { type: 'Enrollment', array: true },
    enrollmentsQuery: { type: 'EnrollmentsQueryResults', array: false },
    events: { type: 'Event', array: true },
    eventsQuery: { type: 'EventsQueryResults', array: false },
    feeRate: { type: 'Float', array: false },
    feeType: { type: 'SiteFeeTypeEnum', array: false },
    forRosterWithInvitesCount: { type: 'Int', array: false },
    formattedCourseDays: { type: 'String', array: false },
    formattedDaysAndTimes: { type: 'String', array: false },
    formattedKind: { type: 'String', array: false },
    gradeGroup: { type: 'String', array: false },
    grades: { type: 'String', array: true },
    hasCheckoutOptions: { type: 'Boolean', array: false },
    hasEnrolledStudents: { type: 'Boolean', array: false },
    hasOptionParents: { type: 'Boolean', array: false },
    hasOptions: { type: 'Boolean', array: false },
    hasParentCourses: { type: 'Boolean', array: false },
    hasParentEnrollments: { type: 'Boolean', array: false },
    hasParentOptionCourses: { type: 'Boolean', array: false },
    homeScore: { type: 'Int', array: false },
    homeTeam: { type: 'Course', array: false },
    id: { type: 'ID', array: false },
    kind: { type: 'CourseKind', array: false },
    lastWithdrawDate: { type: 'ISO8601DateTime', array: false },
    listPrice: { type: 'Float', array: false },
    materialsFee: { type: 'Float', array: false },
    maxCapacity: { type: 'Int', array: false },
    messages: { type: 'Message', array: true },
    minCapacity: { type: 'Int', array: false },
    name: { type: 'String', array: false },
    nameForRoster: { type: 'String', array: false },
    noEnrichmentDays: { type: 'ISO8601Date', array: true },
    notes: { type: 'String', array: false },
    optionParents: { type: 'Enrollment', array: true },
    optionParentsQuery: { type: 'OptionParentsQueryResults', array: false },
    options: { type: 'CourseOption', array: true },
    parent: { type: 'Course', array: false },
    parentCourses: { type: 'Course', array: true },
    parentEnrollments: { type: 'Enrollment', array: true },
    parentEnrollmentsQuery: { type: 'ParentEnrollmentsQueryResults', array: false },
    parentOptionCourses: { type: 'Course', array: true },
    parentVariant: { type: 'Course', array: false },
    parentVariantCourse: { type: 'Course', array: false },
    parents: { type: 'MessageEntity', array: true },
    priceBreakdowns: { type: 'PriceBreakdowns', array: false },
    priceConfigs: { type: 'PriceConfig', array: true },
    prices: { type: 'Prices', array: false },
    rates: { type: 'Rates', array: false },
    removedCount: { type: 'Int', array: false },
    reviewAverage: { type: 'Float', array: false },
    reviewCount: { type: 'Int', array: false },
    reviews: { type: 'Review', array: true },
    room: { type: 'String', array: false },
    scholarshipCode: { type: 'String', array: false },
    score: { type: 'String', array: false },
    searchable: { type: 'Boolean', array: false },
    season: { type: 'Season', array: false },
    seasonId: { type: 'ID', array: false },
    site: { type: 'Site', array: false },
    siteGradeLabels: { type: 'String', array: true },
    siteId: { type: 'ID', array: false },
    spotsLeftByDay: { type: 'JSON', array: false },
    startDate: { type: 'ISO8601Date', array: false },
    status: { type: 'CourseStatus', array: false },
    supplies: { type: 'String', array: true },
    teacher: { type: 'Teacher', array: false },
    teacherId: { type: 'ID', array: false },
    teamMaxCapacity: { type: 'Int', array: false },
    token: { type: 'String', array: false },
    usageBillingDate: { type: 'ISO8601Date', array: false },
    userCompanyRole: { type: 'MemberRole', array: false },
    userCourseRole: { type: 'MemberRole', array: false },
    userFamilyRole: { type: 'MemberRole', array: false },
    userSiteRole: { type: 'MemberRole', array: false },
    vendor: { type: 'Vendor', array: false },
    waitlistOpen: { type: 'Boolean', array: false },
    waitlistedCount: { type: 'Int', array: false }
  },
  WithTableViews: {
    about: { type: 'String', array: false },
    address: { type: 'String', array: false },
    cancellationPolicy: { type: 'VendorGuideline', array: false },
    city: { type: 'String', array: false },
    courseCancellationPolicy: { type: 'String', array: false },
    courseFrameworks: { type: 'CourseFramework', array: true },
    courseFrameworksQuery: { type: 'CourseFrameworksQueryResults', array: false },
    courseKindGroups: { type: 'CourseKindGroup', array: true },
    courses: { type: 'Course', array: true },
    email: { type: 'String', array: false },
    hasCourseFrameworks: { type: 'Boolean', array: false },
    hasCourses: { type: 'Boolean', array: false },
    hasMutations: { type: 'Boolean', array: false },
    hasParticipants: { type: 'Boolean', array: false },
    hasRequests: { type: 'Boolean', array: false },
    hasTableViews: { type: 'Boolean', array: false },
    hasTeachers: { type: 'Boolean', array: false },
    hasTeamAdmins: { type: 'Boolean', array: false },
    hasTeamMembers: { type: 'Boolean', array: false },
    headline: { type: 'String', array: false },
    history: { type: 'HistoryVersion', array: true },
    id: { type: 'ID', array: false },
    mutationChanges: { type: 'MutationChange', array: true },
    mutationChangesQuery: { type: 'MutationChangesQueryResults', array: false },
    mutations: { type: 'Mutation', array: true },
    mutationsQuery: { type: 'MutationsQueryResults', array: false },
    name: { type: 'String', array: false },
    nonPartnerApproved: { type: 'Boolean', array: false },
    participants: { type: 'Participant', array: true },
    participantsQuery: { type: 'ParticipantsQueryResults', array: false },
    payouts: { type: 'Payout', array: true },
    pendingPayoutTotal: { type: 'Float', array: false },
    phone: { type: 'String', array: false },
    profileBackground: { type: 'String', array: false },
    profilePhoto: { type: 'String', array: false },
    quickStartGuide: { type: 'CompanyQuickStartGuide', array: false },
    sites: { type: 'Site', array: true },
    sitesQuery: { type: 'SitesQueryResults', array: false },
    size: { type: 'Int', array: false },
    slug: { type: 'String', array: false },
    state: { type: 'String', array: false },
    stripeAccount: { type: 'String', array: false },
    stripeAccountType: { type: 'String', array: false },
    stripeAccountVerified: { type: 'Boolean', array: false },
    stripeBalance: { type: 'Float', array: false },
    stripeCanIssueRefunds: { type: 'Boolean', array: false },
    stripeConnectUrl: { type: 'String', array: false },
    stripeDashboardUrl: { type: 'String', array: false },
    tableViews: { type: 'TableView', array: true },
    tableViewsQuery: { type: 'TableViewsQueryResults', array: false },
    tableViewsUpsert: { type: 'Boolean', array: false },
    tableViewsUpsertPersonal: { type: 'Boolean', array: false },
    teachers: { type: 'Teacher', array: true },
    teachersQuery: { type: 'TeachersQueryResults', array: false },
    teachingRadius: { type: 'Int', array: false },
    teamAdmins: { type: 'Member', array: true },
    teamAdminsQuery: { type: 'TeamAdminsQueryResults', array: false },
    teamChangeRole: { type: 'Boolean', array: false },
    teamInvites: { type: 'MemberInvitation', array: true },
    teamMembers: { type: 'Member', array: true },
    teamMembersQuery: { type: 'TeamMembersQueryResults', array: false },
    teamParticipantsUpsert: { type: 'Boolean', array: false },
    teamSetAsContact: { type: 'Boolean', array: false },
    timezone: { type: 'String', array: false },
    timezoneDisplay: { type: 'String', array: false },
    upcomingActiveOrRecentCourses: { type: 'Course', array: true },
    userCompanyRole: { type: 'MemberRole', array: false },
    userCourseRole: { type: 'MemberRole', array: false },
    userFamilyRole: { type: 'MemberRole', array: false },
    userMemberRole: { type: 'MemberRole', array: false },
    userSiteRole: { type: 'MemberRole', array: false },
    vendors: { type: 'Vendor', array: true },
    website: { type: 'String', array: false },
    zipcode: { type: 'String', array: false },
    active: { type: 'Boolean', array: false },
    attendanceQrCode: { type: 'String', array: false },
    attendanceUrl: { type: 'String', array: false },
    blockOverlappingEnrollmentsDefault: { type: 'Boolean', array: false },
    branding: { type: 'SiteBranding', array: false },
    cartExpirationTimeMinutes: { type: 'Int', array: false },
    charityName: { type: 'String', array: false },
    checkoutDestinations: { type: 'String', array: true },
    classrooms: { type: 'Classroom', array: true },
    complianceDefinitions: { type: 'ComplianceDefinitions', array: false },
    complianceReport: { type: 'ComplianceReportRow', array: true },
    contact: { type: 'Contact', array: false },
    contacts: { type: 'Contact', array: true },
    directoryCourseTags: { type: 'CourseTag', array: true },
    discountDefinitions: { type: 'DiscountDefinition', array: true },
    discountDefinitionsQuery: { type: 'DiscountDefinitionsQueryResults', array: false },
    donationConfig: { type: 'DonationConfig', array: false },
    familyParticipants: { type: 'Participant', array: true },
    familyParticipantsQuery: { type: 'FamilyParticipantsQueryResults', array: false },
    feeRate: { type: 'Float', array: false },
    feeType: { type: 'SiteFeeTypeEnum', array: false },
    gameSeasons: { type: 'Season', array: true },
    grades: { type: 'Grade', array: true },
    hasDiscountDefinitions: { type: 'Boolean', array: false },
    hasFamilyParticipants: { type: 'Boolean', array: false },
    hasSiteCompanies: { type: 'Boolean', array: false },
    hasVendors: { type: 'Boolean', array: false },
    homeroomRate: { type: 'Float', array: false },
    hubspotId: { type: 'String', array: false },
    latestSeason: { type: 'Season', array: false },
    organizationType: { type: 'Organization', array: false },
    parentAttendanceConfig: { type: 'ParentAttendanceConfig', array: false },
    partner: { type: 'Boolean', array: false },
    payoutConfig: { type: 'SitePayoutConfig', array: false },
    pendingRefunds: { type: 'Refund', array: true },
    pickupDestinations: { type: 'String', array: true },
    policy: { type: 'Policy', array: false },
    priceTiers: { type: 'PriceTierUse', array: true },
    processingRefunds: { type: 'Refund', array: true },
    proration: { type: 'ProrationKind', array: false },
    prorationConfig: { type: 'ProrationConfig', array: false },
    recentSeasons: { type: 'Season', array: true },
    registrationGroups: { type: 'String', array: true },
    restrictedAccountRegistration: { type: 'Boolean', array: false },
    retired: { type: 'Boolean', array: false },
    searchable: { type: 'Boolean', array: false },
    seasons: { type: 'Season', array: true },
    seasonsByStatus: { type: 'Season', array: true },
    siteCompanies: { type: 'Company', array: true },
    streetAddress: { type: 'String', array: false },
    studentAssignableClassrooms: { type: 'Classroom', array: true },
    students: { type: 'Student', array: true },
    studentsQuery: { type: 'StudentsQueryResults', array: false },
    tableviewsQuery: { type: 'TableViewsQueryResults', array: false },
    timeEntryQrCode: { type: 'String', array: false },
    timeEntryUrl: { type: 'String', array: false },
    trackingParameters: { type: 'SiteTrackingParameter', array: true },
    txnFeeRate: { type: 'Float', array: false },
    usingAch: { type: 'Boolean', array: false },
    usingAlipay: { type: 'Boolean', array: false },
    usingCompliance: { type: 'Boolean', array: false },
    usingNewRegistration: { type: 'Boolean', array: false },
    usingParentAttendance: { type: 'Boolean', array: false },
    usingPickups: { type: 'Boolean', array: false },
    usingPriceTiers: { type: 'Boolean', array: false },
    usingTrackingParameters: { type: 'Boolean', array: false },
    vendorsQuery: { type: 'VendorsQueryResults', array: false },
    waivers: { type: 'FileUpload', array: true }
  },
  WithTeam: {
    about: { type: 'String', array: false },
    address: { type: 'String', array: false },
    cancellationPolicy: { type: 'VendorGuideline', array: false },
    city: { type: 'String', array: false },
    courseCancellationPolicy: { type: 'String', array: false },
    courseFrameworks: { type: 'CourseFramework', array: true },
    courseFrameworksQuery: { type: 'CourseFrameworksQueryResults', array: false },
    courseKindGroups: { type: 'CourseKindGroup', array: true },
    courses: { type: 'Course', array: true },
    email: { type: 'String', array: false },
    hasCourseFrameworks: { type: 'Boolean', array: false },
    hasCourses: { type: 'Boolean', array: false },
    hasMutations: { type: 'Boolean', array: false },
    hasParticipants: { type: 'Boolean', array: false },
    hasRequests: { type: 'Boolean', array: false },
    hasTableViews: { type: 'Boolean', array: false },
    hasTeachers: { type: 'Boolean', array: false },
    hasTeamAdmins: { type: 'Boolean', array: false },
    hasTeamMembers: { type: 'Boolean', array: false },
    headline: { type: 'String', array: false },
    history: { type: 'HistoryVersion', array: true },
    id: { type: 'ID', array: false },
    mutationChanges: { type: 'MutationChange', array: true },
    mutationChangesQuery: { type: 'MutationChangesQueryResults', array: false },
    mutations: { type: 'Mutation', array: true },
    mutationsQuery: { type: 'MutationsQueryResults', array: false },
    name: { type: 'String', array: false },
    nonPartnerApproved: { type: 'Boolean', array: false },
    participants: { type: 'Participant', array: true },
    participantsQuery: { type: 'ParticipantsQueryResults', array: false },
    payouts: { type: 'Payout', array: true },
    pendingPayoutTotal: { type: 'Float', array: false },
    phone: { type: 'String', array: false },
    profileBackground: { type: 'String', array: false },
    profilePhoto: { type: 'String', array: false },
    quickStartGuide: { type: 'CompanyQuickStartGuide', array: false },
    sites: { type: 'Site', array: true },
    sitesQuery: { type: 'SitesQueryResults', array: false },
    size: { type: 'Int', array: false },
    slug: { type: 'String', array: false },
    state: { type: 'String', array: false },
    stripeAccount: { type: 'String', array: false },
    stripeAccountType: { type: 'String', array: false },
    stripeAccountVerified: { type: 'Boolean', array: false },
    stripeBalance: { type: 'Float', array: false },
    stripeCanIssueRefunds: { type: 'Boolean', array: false },
    stripeConnectUrl: { type: 'String', array: false },
    stripeDashboardUrl: { type: 'String', array: false },
    tableViews: { type: 'TableView', array: true },
    tableViewsQuery: { type: 'TableViewsQueryResults', array: false },
    tableViewsUpsert: { type: 'Boolean', array: false },
    tableViewsUpsertPersonal: { type: 'Boolean', array: false },
    teachers: { type: 'Teacher', array: true },
    teachersQuery: { type: 'TeachersQueryResults', array: false },
    teachingRadius: { type: 'Int', array: false },
    teamAdmins: { type: 'Member', array: true },
    teamAdminsQuery: { type: 'TeamAdminsQueryResults', array: false },
    teamChangeRole: { type: 'Boolean', array: false },
    teamInvites: { type: 'MemberInvitation', array: true },
    teamMembers: { type: 'Member', array: true },
    teamMembersQuery: { type: 'TeamMembersQueryResults', array: false },
    teamParticipantsUpsert: { type: 'Boolean', array: false },
    teamSetAsContact: { type: 'Boolean', array: false },
    timezone: { type: 'String', array: false },
    timezoneDisplay: { type: 'String', array: false },
    upcomingActiveOrRecentCourses: { type: 'Course', array: true },
    userCompanyRole: { type: 'MemberRole', array: false },
    userCourseRole: { type: 'MemberRole', array: false },
    userFamilyRole: { type: 'MemberRole', array: false },
    userMemberRole: { type: 'MemberRole', array: false },
    userSiteRole: { type: 'MemberRole', array: false },
    vendors: { type: 'Vendor', array: true },
    website: { type: 'String', array: false },
    zipcode: { type: 'String', array: false },
    attendances: { type: 'Attendance', array: true },
    attendancesQuery: { type: 'AttendancesQueryResults', array: false },
    ccContacts: { type: 'Contact', array: true },
    contacts: { type: 'Contact', array: true },
    creditCard: { type: 'CreditCard', array: false },
    enrollments: { type: 'Enrollment', array: true },
    enrollmentsQuery: { type: 'EnrollmentsQueryResults', array: false },
    hasAttendances: { type: 'Boolean', array: false },
    hasPaymentMethod: { type: 'Boolean', array: false },
    invoice: { type: 'Boolean', array: false },
    parents: { type: 'GuardedUser', array: true },
    receipts: { type: 'Receipt', array: true },
    students: { type: 'Student', array: true },
    usBankAccount: { type: 'UsBankAccount', array: false },
    user: { type: 'User', array: false },
    active: { type: 'Boolean', array: false },
    attendanceQrCode: { type: 'String', array: false },
    attendanceUrl: { type: 'String', array: false },
    blockOverlappingEnrollmentsDefault: { type: 'Boolean', array: false },
    branding: { type: 'SiteBranding', array: false },
    cartExpirationTimeMinutes: { type: 'Int', array: false },
    charityName: { type: 'String', array: false },
    checkoutDestinations: { type: 'String', array: true },
    classrooms: { type: 'Classroom', array: true },
    complianceDefinitions: { type: 'ComplianceDefinitions', array: false },
    complianceReport: { type: 'ComplianceReportRow', array: true },
    contact: { type: 'Contact', array: false },
    directoryCourseTags: { type: 'CourseTag', array: true },
    discountDefinitions: { type: 'DiscountDefinition', array: true },
    discountDefinitionsQuery: { type: 'DiscountDefinitionsQueryResults', array: false },
    donationConfig: { type: 'DonationConfig', array: false },
    familyParticipants: { type: 'Participant', array: true },
    familyParticipantsQuery: { type: 'FamilyParticipantsQueryResults', array: false },
    feeRate: { type: 'Float', array: false },
    feeType: { type: 'SiteFeeTypeEnum', array: false },
    gameSeasons: { type: 'Season', array: true },
    grades: { type: 'Grade', array: true },
    hasDiscountDefinitions: { type: 'Boolean', array: false },
    hasFamilyParticipants: { type: 'Boolean', array: false },
    hasSiteCompanies: { type: 'Boolean', array: false },
    hasVendors: { type: 'Boolean', array: false },
    homeroomRate: { type: 'Float', array: false },
    hubspotId: { type: 'String', array: false },
    latestSeason: { type: 'Season', array: false },
    organizationType: { type: 'Organization', array: false },
    parentAttendanceConfig: { type: 'ParentAttendanceConfig', array: false },
    partner: { type: 'Boolean', array: false },
    payoutConfig: { type: 'SitePayoutConfig', array: false },
    pendingRefunds: { type: 'Refund', array: true },
    pickupDestinations: { type: 'String', array: true },
    policy: { type: 'Policy', array: false },
    priceTiers: { type: 'PriceTierUse', array: true },
    processingRefunds: { type: 'Refund', array: true },
    proration: { type: 'ProrationKind', array: false },
    prorationConfig: { type: 'ProrationConfig', array: false },
    recentSeasons: { type: 'Season', array: true },
    registrationGroups: { type: 'String', array: true },
    restrictedAccountRegistration: { type: 'Boolean', array: false },
    retired: { type: 'Boolean', array: false },
    searchable: { type: 'Boolean', array: false },
    seasons: { type: 'Season', array: true },
    seasonsByStatus: { type: 'Season', array: true },
    siteCompanies: { type: 'Company', array: true },
    streetAddress: { type: 'String', array: false },
    studentAssignableClassrooms: { type: 'Classroom', array: true },
    studentsQuery: { type: 'StudentsQueryResults', array: false },
    tableviewsQuery: { type: 'TableViewsQueryResults', array: false },
    timeEntryQrCode: { type: 'String', array: false },
    timeEntryUrl: { type: 'String', array: false },
    trackingParameters: { type: 'SiteTrackingParameter', array: true },
    txnFeeRate: { type: 'Float', array: false },
    usingAch: { type: 'Boolean', array: false },
    usingAlipay: { type: 'Boolean', array: false },
    usingCompliance: { type: 'Boolean', array: false },
    usingNewRegistration: { type: 'Boolean', array: false },
    usingParentAttendance: { type: 'Boolean', array: false },
    usingPickups: { type: 'Boolean', array: false },
    usingPriceTiers: { type: 'Boolean', array: false },
    usingTrackingParameters: { type: 'Boolean', array: false },
    vendorsQuery: { type: 'VendorsQueryResults', array: false },
    waivers: { type: 'FileUpload', array: true }
  }
};

function resolveTypes() {
  for (const typeName in gqlTypes) {
    const type = gqlTypes[typeName as keyof typeof gqlTypes];

    for (const propName in type) {
      const propInfo = type[propName as keyof typeof type];
      propInfo.resolvedType = gqlTypes[propInfo.type as keyof typeof gqlTypes];
    }
  }
}

resolveTypes();
